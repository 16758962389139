import ForgotPassword from "../pages/forgotpassword";
import Login from "../pages/login";
import SignUp from "../pages/signup";
import News from "../pages/news";
import SubscriptionPlan from "../pages/subscriptionplan";
import Profile from "../pages/profile";
import AskLeo from "pages/askLeo";
import Support from "pages/support";
import NotesHistory from "pages/notes";
import SupportMessage from "components/support/support";
import Guideline from "pages/guidelines";

const routes = [
  {
    path: "/",
    authenticated: false,
    component: Login,
    layout: false,
  },
  {
    path: "/forgot-password",
    authenticated: false,
    component: ForgotPassword,
    layout: false,
  },
  {
    path: "/signup",
    authenticated: false,
    component: SignUp,
    layout: false,
  },
  {
    path: "/home/news",
    authenticated: true,
    component: News,
    layout: true,
  },
  {
    path: "/allnotes/notes/:chatId",
    authenticated: true,
    component: NotesHistory,
    layout: true,
  },
  {
    path: "/subscription/plan",
    authenticated: true,
    component: SubscriptionPlan,
    layout: true,
  },
  {
    path: "/home/ask-leo",
    authenticated: true,
    component: AskLeo,
    layout: true,
  },
  {
    path: "/setting/profile",
    authenticated: true,
    component: Profile,
    layout: true,
  },
  {
    path: "/setting/faqs",
    authenticated: true,
    component: () => <Guideline guidelineType="faqs" />,
    layout: true,
  },
  {
    path: "setting/terms-and-conditions",
    authenticated: true,
    component: () => <Guideline guidelineType="terms_and_conditions" />,
    layout: true,
  },
  {
    path: "/setting/privacy-policy",
    authenticated: true,
    component: () => <Guideline guidelineType="privacy_policy" />,
    layout: true,
  },
  {
    path: "/setting/about",
    authenticated: true,
    component: () => <Guideline guidelineType="about_us" />,
    layout: true,
  },
  {
    path: "/setting/support",
    authenticated: true,
    component: Support,
    layout: true,
  },
  {
    path: "/setting/support/:conversation_id",
    authenticated: true,
    component: SupportMessage,
    layout: true,
  },
];

export default routes;
