import API from 'APIs/base'
import { ENDPOINT } from 'config/constants'

const getUserChatTitle = async () => {
    return await API.getMethod(ENDPOINT.chatBot.getUserChatTitle, true);
}

const createChat = async (data) => {
    return await API.postMethod(ENDPOINT.chatBot.createChat, true, data,true,false)
}

const updateChat = async (data) => {
    return await API.postMethod(ENDPOINT.chatBot.updateChat, true, data,true,false)
}

const getChatHistory = async (chat_id)=>{
    return await API.postMethod(ENDPOINT.chatBot.getChatHistory,true,{chat_id});
}
const deleteChat = async (chat_id)=>{
    console.log("chat_id",chat_id)
    return await API.deleteMethod(ENDPOINT.chatBot.deleteChat,true,{chat_id});
}




// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getUserChatTitle,
    createChat,
    updateChat,
    getChatHistory,
    deleteChat
}