import profileImage from "../images//user-image-icon.webp";
import { useEffect, useState } from 'react';
import '../style/profileNav.css';
import fileIcon from '../images/file.png';
import mobileIcon from '../images/phone.png';
import infoIcon from '../images/info.png'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

export default function ProfileNav() {
    const location = useLocation();
    const [accountOpen, setAccountOpen] = useState(false);
    const [appOpen, setAppOpen] = useState(false);
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const { user } = useSelector((state) => state.auth)

    const toggleNav = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    const toggleAccount = () => {
        setAccountOpen(!accountOpen);
    };

    const toggleApp = () => {
        setAppOpen(!appOpen);
    };

    useEffect(() => {
        if (location.pathname.includes('/profile')) {
            setAccountOpen(true);
        } else if (
            location.pathname.includes('/terms-and-conditions') ||
            location.pathname.includes('/privacy-policy') ||
            location.pathname.includes('/about') ||
            location.pathname.includes('/faqs') ||
            location.pathname.includes('/support')
        ) {
            setAppOpen(true);
        }
    }, [location.pathname]);
    return (
        <>
            <i className="fa-solid fa-arrow-right" id='profile-NavOpener' onClick={toggleNav}></i>
            <div className={`profile-nav ${isMobileNavOpen ? 'mobile-nav' : ''}`}>
                <i className="fa-regular fa-circle-xmark" id='profile-NavCloser' onClick={toggleNav}></i>
                <h1>Settings</h1>
                <hr />
                <label onClick={toggleAccount} className="toggle-label">
                    Account
                    <i className="fa-solid fa-sort-down"></i>
                </label>
                {accountOpen && (

                    <div className="account">
                        <a href='/setting/profile'>
                            <img className="profile-img" src={user?.user_image || profileImage} alt="profile" />
                            <label>Profile</label>
                        </a>
                    </div>
                )}

                <label onClick={toggleApp} className="toggle-label">
                    App
                    <i className="fa-solid fa-sort-down"></i>
                </label>
                {appOpen && (
                    <div className="app-links">
                        <li><Link to="/setting/faqs"><i><img src={fileIcon} alt="file"></img></i>FAQs</Link></li>
                        <li><Link to="/setting/terms-and-conditions"><i><img src={fileIcon} alt="file"></img></i>Terms and Conditions</Link></li>
                        <li><Link to="/setting/privacy-policy"><i><img src={fileIcon} alt="file"></img></i>Privacy Policy</Link></li>
                        <li><Link to="/setting/support"><i><img src={mobileIcon} alt="file"></img></i>Support</Link></li>
                        <li><Link to="/setting/about"><i><img src={infoIcon} alt="file"></img></i>About the App</Link></li>
                    </div>
                )}

            </div>
        </>
    );
}