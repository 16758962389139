import API from 'APIs/base'
import { ENDPOINT } from 'config/constants'

const updateUser = async(data)=>{
    return await API.postMethod(ENDPOINT.user.updateUser,true,data,true,false);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    updateUser
}