// LoginCard.js
import React from 'react';
import LeftSideComponent from '../cybercard/cybercard';
import "../../style/card.css";
import JobInfoCard from '../jobinfo';


export default function JobInfo ({ formData, updateFormData })  {
  return (
    <section className="card" id='SignUp'>
      <LeftSideComponent
        title1="Enter Your"
        title2="Details"
      />
      <JobInfoCard formData={formData} updateFormData={updateFormData}/>
    </section>
  );
};