import React from 'react';
import LeftSideComponent from '../cybercard/cybercard';
import "../../style/card.css";
import UserInfoCard from '../userinfo';


export default function UserInfo({ formData, updateFormData })  {
  return (
    <section className="card" id='SignUp'>
      <LeftSideComponent
        title1="Register Yourself"
        title2="on Leo.ai"
      />
      <UserInfoCard formData={formData} updateFormData={updateFormData}/>
    </section>
  );
};