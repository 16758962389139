import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import routes from './routes';  
import Layout from '../Layout/layout'; 
import { useSelector } from "react-redux";

export default function IndexRoutes() {
  const {accessToken} = useSelector((state) => state.auth);  

  const handleRoute = (path, authenticated, component, layout) => {
    // Handle unauthenticated route
    if (!authenticated) {
      if (accessToken) {
        if (['/', '/forgot-password', '/change-password', '/newpassword', '/signup'].includes(path)) {
          return <Navigate to="/home/ask-leo" />;
        }
      }
      return layout ? <Layout>{component}</Layout> : component;
    }

    // Handle authenticated route
    if (authenticated) {
      if (accessToken) {
        return layout ? <Layout>{component}</Layout> : component;
      } else {
        return <Navigate to="/" />;
      }
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => {
          const { path, component: Component, authenticated, layout } = route;
          return (
            <Route
              key={`route_${index}`}
              path={path}
              element={handleRoute(path, authenticated, <Component />, layout)}  // Pass layout condition
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
}
