import API from 'APIs/base'
import { ENDPOINT } from 'config/constants'

const aboutUs = async (params) => {
    const query = new URLSearchParams(params).toString(); // Convert params to query string
    return await API.getMethod(`${ENDPOINT.guidelines.aboutUs}?${query}`, true);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    aboutUs,

}