import React, { useState } from "react";
import "../style/login.css";
import Logo from "../images/logo.png";
import { useWizard } from "react-use-wizard";
import { useFormik } from "formik";

export default function JobInfoCard({ formData, updateFormData }) {
    const { nextStep, previousStep } = useWizard();

    const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
    const [ageDropdownOpen, setAgeDropdownOpen] = useState(false);
    const [yearsDropdownOpen, setYearsDropdownOpen] = useState(false);
    const [jobDropdownOpen, setJobDropdownOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            service: formData?.service || 'Select an option',
            age: formData?.age || 'Select an option',
            yearOfService: formData?.yearOfService || 'Select an option',
            job: formData?.job || 'Select an option',
        },
        validateOnChange: false,
        validateOnBlur: false,
        validate: (values) => {
            const errors = {};
            if (values.service === 'Select an option') {
                errors.service = 'Please select a service.';
            }
            if (values.age === 'Select an option') {
                errors.age = 'Please select a age.';
            }
            if (values.yearOfService === 'Select an option') {
                errors.yearOfService = 'Please select years of service.';
            }
            if (values.job === 'Select an option') {
                errors.job = 'Please select a job.';
            }
            return errors;
        },
        onSubmit: (values) => {
            if (!Object.keys(formik.errors).length) {
                updateFormData(values);
                nextStep();
            }
        },
    });


    const handleSelectOption = (option, field) => {
        formik.setFieldValue(field, option);
        formik.setFieldTouched(field, true);
        formik.validateField(field);


        if (field === 'service') setServiceDropdownOpen(false);
        formik.setFieldTouched(field, false);
        if (field === 'age') setAgeDropdownOpen(false);
        formik.setFieldTouched(field, false);
        if (field === 'yearOfService') setYearsDropdownOpen(false);
        formik.setFieldTouched(field, false);
        if (field === 'job') setJobDropdownOpen(false);
        formik.setFieldTouched(field, false);
    };

    return (
        <div className="card" id="login-card">
            <div className="Form">
                <div className="logo" id="mobile-logo">
                    <img src={Logo} alt="logo" />
                    <h1>Leo.ai</h1>
                </div>
                <div className="Backbutton" onClick={previousStep}>
                    <i className="fa-solid fa-angle-left"></i>
                    <h1>Back</h1>
                </div>
                <div className="right-side-component">
                    <form className="login-register text-start mt-20" onSubmit={formik.handleSubmit}>

                        {/* Service Dropdown */}
                        <div className="form-group">
                            <label className="form-label" htmlFor="service">Service</label>
                            <div className={`custom-dropdown form-control ${formik.touched.service && formik.errors.service ? 'animate__animated animation__flash ' : ''}`} onClick={() => setServiceDropdownOpen(!serviceDropdownOpen)}>
                                <div className="custom-dropdown-selected">
                                    {formik.values.service}
                                    <span className="custom-arrow"><i className="fa-solid fa-angle-down"></i></span>
                                </div>
                                {serviceDropdownOpen && (
                                    <ul className="custom-dropdown-menu">
                                        {['CIB', 'Detective', 'Forensic', 'Patrol'].map(option => (
                                            <li
                                                key={option}
                                                className={`custom-dropdown-item ${formik.values.service === option ? 'selected' : ''}`}
                                                onClick={() => handleSelectOption(option, 'service')}
                                            >
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            {formik.touched.service && formik.errors.service && <div className="error">{formik.errors.service}</div>}
                        </div>

                        {/* Age Dropdown */}
                        <div className="form-group">
                            <label className="form-label" htmlFor="age">Age</label>
                            <div className={`custom-dropdown form-control ${formik.touched.age && formik.errors.age ? 'animate__animated animation__flash ' : ''}`} onClick={() => setAgeDropdownOpen(!ageDropdownOpen)}>
                                <div className="custom-dropdown-selected">
                                    {formik.values.age}
                                    <span className="custom-arrow"><i className="fa-solid fa-angle-down"></i></span>
                                </div>
                                {ageDropdownOpen && (
                                    <ul className="custom-dropdown-menu">
                                        {['18  - 22 Years old', '23 - 27  Years old', '28 - 32  Years old', '33 - 37  Years old'].map(option => (
                                            <li
                                                key={option}
                                                className={`custom-dropdown-item ${formik.values.age === option ? 'selected' : ''}`}
                                                onClick={() => handleSelectOption(option, 'age')}
                                            >
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            {formik.touched.age && formik.errors.age && <div className="error">{formik.errors.age}</div>}
                        </div>

                        {/* Years of Service Dropdown */}
                        <div className="form-group">
                            <label className="form-label" htmlFor="yearOfService">Years of service</label>
                            <div className={`custom-dropdown form-control ${formik.touched.yearOfService && formik.errors.yearOfService ? 'animate__animated animation__flash ' : ''}`} onClick={() => setYearsDropdownOpen(!yearsDropdownOpen)}>
                                <div className="custom-dropdown-selected">
                                    {formik.values.yearOfService}
                                    <span className="custom-arrow"><i className="fa-solid fa-angle-down"></i></span>
                                </div>
                                {yearsDropdownOpen && (
                                    <ul className="custom-dropdown-menu">
                                        {['3 - 5 Years of service', '6 - 8 Years of service', '9 - 11 Years of service', '12 - 14 Years of service'].map(option => (
                                            <li
                                                key={option}
                                                className={`custom-dropdown-item ${formik.values.yearOfService === option ? 'selected' : ''}`}
                                                onClick={() => handleSelectOption(option, 'yearOfService')}
                                            >
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            {formik.touched.yearOfService && formik.errors.yearOfService && <div className="error">{formik.errors.yearOfService}</div>}
                        </div>

                        {/* Job Dropdown */}
                        <div className="form-group">
                            <label className="form-label" htmlFor="job">Job</label>
                            <div className={`custom-dropdown form-control ${formik.touched.job && formik.errors.job ? 'animate__animated animation__flash ' : ''}`} onClick={() => setJobDropdownOpen(!jobDropdownOpen)}>
                                <div className="custom-dropdown-selected">
                                    {formik.values.job}
                                    <span className="custom-arrow"><i className="fa-solid fa-angle-down"></i></span>
                                </div>
                                {jobDropdownOpen && (
                                    <ul className="custom-dropdown-menu">
                                        {['CIB', 'Detective', 'Forensic', 'Patrol'].map(option => (
                                            <li
                                                key={option}
                                                className={`custom-dropdown-item ${formik.values.job === option ? 'selected' : ''}`}
                                                onClick={() => handleSelectOption(option, 'job')}
                                            >
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            {formik.touched.job && formik.errors.job && <div className="error">{formik.errors.job}</div>}
                        </div>

                        <div className="form-group" id="login">
                            <button type="submit" className="btn-login">Continue</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
