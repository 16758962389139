import NewsImage from '../images/News-image1.png';
import Logo from "../images/logo.png";
export default function News() {
    return (
        <>
            <section className="News-section">
                <div className="News-card">
                    <div className="news-header">
                        <div className='logo'>
                            <div className="image">
                                <img src={Logo} alt="logo"></img>
                            </div>
                            <h1>Leo.ai</h1>
                        </div>
                        <div className="More"><i className="fa-solid fa-ellipsis"></i></div>
                    </div>
                    <div className="news-image-container">
                        <img src={NewsImage} alt="News-Image"></img>
                    </div>
                    <div className="news-content">
                        <div className="app-name">Leo.Ai</div>
                        <div className="news-title">New Privacy Law Passed to Protect Consumer Data</div>
                        <div className="news-time">August 12, 2024</div>
                        <div className="news-description">In a significant move to enhance consumer protection, the government has passed a new privacy law aimed at securing personal data. The legislation, known as the Consumer Data Protection Act (CDPA), imposes stricter regulations on how companies collect, store, and use consumer data. Under the CDPA, companies are required to obtain explicit consent from consumers before using their data and must provide clear options for opting out of data collection.</div>
                    </div>
                </div>
                <div className="News-card">
                    <div className="news-header">
                        <div className='logo'>
                            <div className="image">
                                <img src={Logo} alt="logo"></img>
                            </div>
                            <h1>Leo.ai</h1>
                        </div>
                        <div className="More"><i className="fa-solid fa-ellipsis"></i></div>
                    </div>
                    <div className="news-image-container">
                        <img src={NewsImage} alt="News-Image"></img>
                    </div>
                    <div className="news-content">
                        <div className="app-name">Leo.Ai</div>
                        <div className="news-title">New Privacy Law Passed to Protect Consumer Data</div>
                        <div className="news-time">August 12, 2024</div>
                        <div className="news-description">In a significant move to enhance consumer protection, the government has passed a new privacy law aimed at securing personal data. The legislation, known as the Consumer Data Protection Act (CDPA), imposes stricter regulations on how companies collect, store, and use consumer data. Under the CDPA, companies are required to obtain explicit consent from consumers before using their data and must provide clear options for opting out of data collection.</div>
                    </div>
                </div>
            </section>
        </>
    );
}