import Swal from 'sweetalert2';
import '../../style/sweataleart.css';
import ChatBotAPIs from 'APIs/chatbot';
import { useDispatch } from 'react-redux';
import { newNoteSaved } from '../../redux/reducers/newSaveNotes';
import flag from "../../images/flag.png"
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

export default function Notes({ message, shareLink, copyLink, saveLink, shareHref, copyHref, by_user, chatArray, chatId }) {
    const dispatch = useDispatch();
    const reportId = chatId;
    function handleSaveClick() {
        Swal.fire({
            title: 'Save as Note',
            text: 'Are you sure you want to save this as your note?',
            input: 'text',
            inputPlaceholder: 'Inquiry001',
            showCancelButton: true,
            confirmButtonText: 'Yes, Confirm',
            cancelButtonText: 'No, Cancel',
            showCloseButton: true,
            preConfirm: async (noteName) => {
                if (!noteName) {
                    Swal.showValidationMessage('Please enter a Note name');
                    Swal.hideLoading();
                    Swal.disableButtons();
                    return;
                }
                Swal.showLoading();
                return await createAndUpdateChat(noteName)
                // // Simulate a 2-second delay
                // return new Promise((resolve) => {
                //     setTimeout(() => {
                //         resolve(noteName);
                //     }, 2000);
                // });
            },
            target: '.notes-section',
            customClass: {
                popup: 'swal-popup-custom'
            }
        }).then((result) => {
            console.log("RESULTES ==", result)
            if (result.value) {
                Swal.fire({
                    title: 'Successfully saved!',
                    text: `"${result.value}" saved as your note`,
                    icon: 'success',
                    iconHtml: '<i class="fa-regular fa-circle-check"></i>', // Corrected to use 'class'
                    showCloseButton: true,
                    confirmButtonText: 'Return',
                    customClass: {
                        icon: 'success-icon',
                    },
                    target: '.notes-section',
                });

            }
        });
    }

    const createAndUpdateChat = async (title) => {

        try {
            /**
         * Creating New Chat First
         */
            const chat = await ChatBotAPIs.createChat({ chat: title });
            if (chat) {
                console.log("Chat Created ===", chat?.data?.data.chat_master_id);
                let chatId = chat?.data?.data.chat_master_id;
                if (chatId) {
                    /**
                     * Updating the Chat with Messages Arrray
                     */
                    const updateChat = await ChatBotAPIs.updateChat({ chat_id: chatId, chat: chatArray });
                    if (updateChat) {
                        console.log("Chat updaated Successfullyy == ", updateChat.data);
                        dispatch(
                            newNoteSaved({
                                notes: updateChat.data
                            })
                        );
                        return title
                    }
                }
            }
        } catch (error) {
            console.log(error?.response?.data?.detail)
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error?.response?.data?.detail,
                footer: '<a href="#">Why do I have this issue?</a>'
            });
        }

    }

    return (
        <>
            <section className="notes-section">
                {by_user ? (
                    <div className="prompt">
                        <h1>{message}</h1>
                    </div>
                ) : (
                    <div className="response">
                        <div className="general-response">
                            <p className="response">
                                {/* <span className="response-title">General Response: </span> */}
                                <ReactMarkdown remakPlugins={[gfm]}>{message}</ReactMarkdown>
                            </p>

                        </div>
                        <div className="buttons">
                            {/* Conditionally render the share button */}
                            {shareLink && (
                                <a href={shareHref}>
                                    <i className="fa-solid fa-share-nodes"></i>
                                </a>
                            )}

                            {/* Conditionally render the copy button */}
                            {copyLink && (
                                <a href={copyHref}>
                                    <i className="fa-solid fa-link"></i>
                                </a>
                            )}

                            {saveLink && (
                                <a onClick={handleSaveClick}>
                                    <i className="fa-solid fa-floppy-disk"></i>
                                </a>
                            )}
                        </div>
                        <hr />
                        {/* <div className="link">
                            <span className="response-title"><a>Link: {notes.link}</a></span>
                        </div> */}
                        {reportId !== undefined && reportId !== null ? (
                            <a
                                className="report"
                                href={`/reports/${reportId}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Reported!',
                                        text: 'Your report has been submitted successfully.',
                                        target: '.notes-history-container'
                                    });
                                }}
                            >
                                <img src={flag} alt='flag'></img>
                                <span>Flagged Response</span>
                            </a>
                        ) : (
                            <></>
                        )}
                    </div>
                )}
            </section>
        </>
    );
} 