import API from 'APIs/base'
import { ENDPOINT } from 'config/constants'

const getConversations = async()=>{
    return await API.getMethod(ENDPOINT.support.getConversations,true);
}

const getMessages = async(id)=>{
    return await API.getMethod(`${ENDPOINT.support.getMessages}/${id}`,true);
}

const createConversation = async(data)=>{
    return API.postMethod(ENDPOINT.support.createConversation,true,data,true,false)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getConversations,
    getMessages,
    createConversation
}