import { useState } from 'react';
import Logo from '../images/logo.png';
import '../style/login.css';
import { useWizard } from 'react-use-wizard';
import ImageIcon from '../images/imageicon.png';

export default function ImageUploadCard({ formData, updateFormData }) {
    const { previousStep, nextStep } = useWizard();
    const [selectedImage, setSelectedImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            updateFormData({ imageurl: file });
            setErrorMessage('');
        }
    };


    const handleContinue = (e) => {
        e.preventDefault();
        if (!selectedImage) {
            setErrorMessage('Please upload an image.');
        } else {
            nextStep();
        }
    };

    return (
        <>
            <div className="card image-card" id="login-card">
                <div className="logo" id="mobile-logo">
                    <img src={Logo} alt="logo" />
                    <h1>Leo.ai</h1>
                </div>
                <div className="Backbutton">
                    <div className='backButton' onClick={previousStep}>
                        <i className="fa-solid fa-angle-left"></i>
                        <h1>Back</h1>
                    </div>
                    <div className='SkipButton' onClick={nextStep}>
                        <h1>Skip</h1>
                        <i class="fa-solid fa-angle-right"></i>
                    </div>
                </div>
                <div className="right-side-component" id="password">
                    <form className="login-register text-start mt-20" action="#">

                        <div className="form-group">
                            <div className="image-box">
                                <input
                                    type="file"
                                    id="file-input"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                                <img
                                    src={selectedImage || ImageIcon}
                                    alt="profile-image"
                                    className={selectedImage ? 'uploaded' : ''}
                                />
                            </div>
                        </div>
                        {/* Error message */}
                        {errorMessage && <div className="error">{errorMessage}</div>}

                        {/* Continue button */}
                        <div className="form-group" id="login">
                            <button className="btn-login" name="login" onClick={handleContinue}>
                                Continue
                            </button>
                        </div>
                    </form>
                </div>
            </div >
        </>
    );
}
