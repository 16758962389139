import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useWizard } from "react-use-wizard";
import "../style/login.css";
import Logo from '../images/logo.png';
import * as Yup from "yup";
import AuthAPIs from 'APIs/auth';

export default function UserInfo({ formData, updateFormData }) {
    const { nextStep } = useWizard();  // Wizard navigation hooks
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedGender, setSelectedGender] = useState(formData?.gender || 'Select an option');

    const handleSelectOption = (option, setFieldValue) => {
        setSelectedGender(option);
        setDropdownOpen(false);
        setFieldValue("gender", option);
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email address")
            .test('dot-after-at', 'Dot should be present after "@"', function (value) {
                if (value) {
                    const atIndex = value.indexOf('@');
                    const dotIndex = value.indexOf('.', atIndex);
                    return dotIndex > atIndex && dotIndex !== -1;
                }
                return false;
            })
            .required("Email is required."),
        firstName: Yup.string().required("First name is required.")
            .matches(/^[A-Za-z\s]+$/, 'First name cannot contain numbers or special characters.')

            .matches(/[^\s]+/, 'First name cannot contain only spaces')
            .min(3, 'First name must be at least 3  characters long.'),
        lastName: Yup.string().required("Last name is required.")
            .matches(/^[A-Za-z\s]+$/, 'Last name cannot contain numbers or special characters.')
            .matches(/[^\s]+/, 'Last name cannot contain only spaces')
            .min(3, 'Last name must be at least 3 characters long.'),
        gender: Yup.string().required("Gender is required."),
    });

    const handleSubmit = async (values, { setFieldError, setSubmitting }) => {
        try {
            const response = await AuthAPIs.isUserExist(values.email);
            console.log("Response of email already exist =", response)
            if (response) {
                setFieldError('email', 'This email is already registered');
            } else {
                updateFormData(values);
                nextStep();
                setSubmitting(false);
            }
        } catch (error) {
            return this.createError({ message: "Failed to validate email" });
        }

    };

    return (
        <div className="card" id="login-card">
            <div className="Form">
                <div className='logo' id="mobile-logo">
                    <img src={Logo} alt="logo"></img>
                    <h1>Leo.ai</h1>
                </div>
                <div className="Backbutton" onClick={() => window.location.href = '/'}>
                    <i className="fa-solid fa-angle-left"></i>
                    <h1>Back</h1>
                </div>
                <div className="right-side-component">
                    <Formik
                        initialValues={{
                            firstName: formData?.firstName || '',
                            lastName: formData?.lastName || '',
                            email: formData?.email || '',
                            gender: formData?.gender || '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, errors, touched, setFieldValue }) => (
                            <Form className="login-register text-start mt-20">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="input-1">First name</label>
                                    <Field type="text" name="firstName" className={`form-control ${touched.firstName && errors.firstName ? 'animate__animated animate__flash' : ''}`} />
                                    {touched.firstName && errors.firstName && <ErrorMessage name="firstName" component="div" className="error" />}
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="input-1">Last name</label>
                                    <Field type="text" name="lastName" className={`form-control ${touched.lastName && errors.lastName ? 'animate__animated animate__flash' : ''}`} />
                                    {touched.lastName && errors.lastName && <ErrorMessage name="lastName" component="div" className="error" />}
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="input-1">Email Address</label>
                                    <Field type="email" name="email" className={`form-control ${touched.email && errors.email ? 'animate__animated animate__flash' : ''}`} />
                                    {touched.email && errors.email && <ErrorMessage name="email" component="div" className="error" />}
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="input-1">Gender</label>
                                    <div className={`custom-dropdown form-control ${touched.gender && errors.gender ? 'animate__animated animate__flash' : ''}`} onClick={() => setDropdownOpen(!dropdownOpen)}>
                                        <div className="custom-dropdown-selected">
                                            {selectedGender}
                                            <span className="custom-arrow"><i className="fa-solid fa-angle-down"></i></span>
                                        </div>
                                        {dropdownOpen && (
                                            <ul className="custom-dropdown-menu">
                                                <li className={`custom-dropdown-item ${selectedGender === 'Male' ? 'selected' : ''}`} onClick={() => handleSelectOption('Male', setFieldValue)}> Male </li>
                                                <li className={`custom-dropdown-item ${selectedGender === 'Female' ? 'selected' : ''}`} onClick={() => handleSelectOption('Female', setFieldValue)}> Female </li>
                                            </ul>
                                        )}
                                    </div>
                                    {touched.gender && errors.gender && <ErrorMessage name="gender" component="div" className="error" />}
                                </div>
                                <div className="form-group" id="login">
                                    <button type="submit" className="btn-login" disabled={isSubmitting || Object.keys(errors).length > 0}>Continue</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}
