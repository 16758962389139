import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notes: 0,
};

export const notesSavedSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    newNoteSaved: (state, action) => {
      state.notes = action.payload;
    },
    notesClear: (state) => {
      state.notes = initialState.notes;
    },
    notesUsed: (state, action) => {
      state.notes -= action.payload;
    },
  },
});

export const { newNoteSaved, notesClear, notesUsed } = notesSavedSlice.actions;
export default notesSavedSlice.reducer;
