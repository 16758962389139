import API from 'APIs/base'
import { ENDPOINT } from 'config/constants'

const login = async (email, password,mobile_token) => {
    return await API.postMethod(ENDPOINT.auth.login, false, {
        email,
        password,
        mobile_token
    })
}

const signup = async (data) => {
    return await API.postMethod(ENDPOINT.auth.signup, false, data)
}

const uploodProfileImage = async (data) => {
    return await API.postMethod(ENDPOINT.auth.uploodProfile, false, data, false, false);
}

const isUserExist = async (email) => {
    return await API.postMethod(ENDPOINT.auth.isUserExist, false, { email }, false, false);
}

const socialLogin = async (data) => {
    return await API.postMethod(ENDPOINT.auth.socialLogin, false, data);
}
const forgotPassword = async (data) => {
    return await API.postMethod(ENDPOINT.auth.forgotPassword, false, data, true, false);
}
const resetPassword = async (data) => {
    return await API.postMethod(ENDPOINT.auth.resetPassword, false, data, true, false);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    login,
    signup,
    uploodProfileImage,
    isUserExist,
    socialLogin,
    forgotPassword,
    resetPassword
}