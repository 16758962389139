import React from "react";
import { TailSpin } from "react-loader-spinner";

export default function SpinLoader() {
  return (
    <>
      <TailSpin
        className="text-center"
        visible={true}
        height="20"
        width="100"
        color="#FFFFFF"
        ariaLabel="tail-spin-loading"
        radius="5"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </>
  );
}
