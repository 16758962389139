import React, { useState } from 'react';
import '../style/profileNav.css';
import uploadCloud from '../images/upload-cloud.png';
import user_image from '../images//user-image-icon.webp';
import ProfileNav from 'components/profilenav';
import Delete from '../images/Delete.png';
import { useDispatch, useSelector } from 'react-redux';
import UserAPIs from 'APIs/user';
import toast from 'react-hot-toast';
import { authSuccess } from '../redux/reducers/authSlice';
import AuthAPIs from 'APIs/auth'
import SpinLoader from 'components/SpinLoader';
import Swal from 'sweetalert2'
import "../style/sweataleart.css";

export default function Profile() {
    const [errors, setErrors] = useState({});
    const { user, accessToken } = useSelector((state) => state.auth);
    const [formData, setFormData] = useState({
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        email: user?.email || "",
        gender: user?.gender || "",
        age: user?.age || "",
        yearOfService: user?.yearOfService || "",
        job: user?.job,
        password: '',
        c_password: '',
        user_image: user?.user_image || user_image,
    });
    const isFormValid = formData.firstName && formData.lastName && formData.email;
    const [dropdownOpen, setDropdownOpen] = useState({ gender: false, age: false, yearOfService: false, job: false });
    const dispatch = useDispatch();
    const [apiImg, setApiImg] = useState();
    const [isLoading, setIsLoading] = useState('');


    const handleSelectOption = (value, field) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };


    const getFileName = (path) => {
        return path ? path.split('/').pop() : '';
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const nameRegex = /^[A-Za-z]+$/;
        const minLength = 3;
        const passwordMinLength = 8;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        if (name === "firstName" || name === "lastName") {
            if (!value) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [name]: `${name === "firstName" ? "First" : "Last"} name is required`
                }));
            } else if (!nameRegex.test(value) || value.length < minLength) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [name]: `${name === "firstName" ? "First" : "Last"} name must be at least 3 alphabetic characters`
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [name]: ""
                }));
            }
        }

        if (name === "password" && value.length < passwordMinLength) {
            setErrors(prevErrors => ({
                ...prevErrors,
                password: "Password must be at least 8 characters"
            }));
        } else if (name === "password") {
            setErrors(prevErrors => ({
                ...prevErrors,
                password: ""
            }));
        }
    };




    const handleKeyPress = (e) => {
        const regex = /^[A-Za-z]+$/;
        if (!regex.test(e.key)) {
            e.preventDefault();
        }
    };

    const onClickSaveChanges = (e, type) => {
        e.preventDefault();
        Swal.fire({
            title: `Do you want to save the changes?`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
            customClass: {
                confirmButton: 'profileswal2-confirm',
                denyButton: 'profileswal2-deny',
                cancelButton: 'profileswal2-cancel'
            },
            target: ".profile",
        }).then((result) => {

            if (result.isConfirmed) {
                handleSubmit(type)
            } else if (result.isDenied) {
                Swal.fire({
                    title: "Changes  not saved",
                    text: "",
                    icon: "info",
                    customClass: {
                        confirmButton: 'profileswal2-ok'
                    },
                    target: ".profile"
                })
            }
        });
    }

    const handleSubmit = async (type) => {
        try {
            if (isFormValid) {
                setIsLoading(type);
                console.log("Form Data:", formData);
                if (apiImg) {
                    const formImg = new FormData();
                    formImg.append('file', apiImg);
                    const img = await AuthAPIs.uploodProfileImage(formImg);
                    if (img) {
                        console.log("Image uploaded successfully == =", img.data.user_img);
                        formData['user_image'] = img.data.user_img
                    }
                }
                console.log("Form Data  == ", formData)
                formData.c_password = formData.password;
                const update = await UserAPIs.updateUser(formData);
                if (update) {
                    Swal.fire({
                        title: "Saved!",
                        text: "",
                        icon: "success",
                        customClass: {
                            confirmButton: 'profileswal2-ok'
                        },
                        target: ".profile"
                    })
                    console.log("User Updated Successfully == ", update.data.user);
                    dispatch(
                        authSuccess({
                            user: update.data.user,
                            accessToken: accessToken
                        })
                    );
                    setIsLoading('');
                }

            } else {
                setErrors({
                    firstName: !formData.firstName,
                    lastName: !formData.lastName,
                    email: !formData.email
                });
            }
        } catch (error) {
            setIsLoading('');
            console.log("Error =", JSON.stringify(error?.response?.data?.detail))
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error?.response?.data?.detail,
            });
        }

    };



    const handleImageUpload = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = (event) => {
            const file = event.target.files[0];
            setApiImg(file);
            if (file && file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onload = () => {
                    const uploadedImage = reader.result;
                    setFormData(prev => ({
                        ...prev,
                        user_image: uploadedImage,
                        userImageName: file.name
                    }));
                };
                reader.readAsDataURL(file);
            } else {
                alert("Please select a valid image file.");
            }
        };
        input.click();
    };


    const handleDeleteImage = () => {
        setFormData(prev => ({
            ...prev,
            user_image: user_image
        }));
    };

    return (
        <div className="profile">
            <ProfileNav />
            <div className='profile-content'>
                <div className='personalInfo'>
                    <label>Personal Information</label>
                    <div className='image-box'>
                        <img src={formData.user_image} alt='user' title={getFileName(formData.user_image)} />
                        <div className='button'>
                            <i onClick={handleImageUpload}><img src={uploadCloud} alt="cloud"></img></i>
                            <i onClick={handleDeleteImage}><img src={Delete} alt='delete'></img></i>
                        </div>
                    </div>
                    <form>
                        <label>First Name</label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            placeholder="Argon"
                            className={errors.firstName ? "input-error" : ""}
                        />
                        {errors.firstName && <span className="error">{errors.firstName}</span>}
                        <label>Last Name</label>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            placeholder="Begin"
                            className={errors.lastName ? "input-error" : ""}
                        />
                        {errors.lastName && <span className="error">{errors.lastName}</span>}
                        <label>Email Address</label>
                        <input disabled type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Aaronbegin@gmail.com" className={errors.email ? "input-error" : ""} />
                        {errors.email && <span className="error">Email Address Is Required</span>}

                        <div className="form-group">
                            <label className="form-label" htmlFor="gender">Gender</label>
                            <div className="custom-dropdown form-control" onClick={() => setDropdownOpen({ ...dropdownOpen, gender: !dropdownOpen.gender })}>
                                <div className={`custom-dropdown-selected ${formData.gender ? '' : 'empty'}`}>
                                    {formData.gender}
                                    <span className="custom-arrow"><i className="fa-solid fa-angle-down"></i></span>
                                </div>
                                {dropdownOpen.gender && (
                                    <ul className="custom-dropdown-menu">
                                        <li className={`custom-dropdown-item ${formData.gender === 'Male' ? 'selected' : ''}`} onClick={() => handleSelectOption('Male', 'gender')}> Male </li>
                                        <li className={`custom-dropdown-item ${formData.gender === 'Female' ? 'selected' : ''}`} onClick={() => handleSelectOption('Female', 'gender')}> Female </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
                <div className='securitySettings'>
                    <label>Security Settings</label>
                    <form onSubmit={(e) => { onClickSaveChanges(e, 'Changes') }}>

                        <div className="form-group">
                            <label className="form-label" htmlFor="age">Age</label>
                            <div className="custom-dropdown form-control" onClick={() => setDropdownOpen({ ...dropdownOpen, age: !dropdownOpen.age })}>
                                <div  className={`custom-dropdown-selected ${formData.age ? '' : 'empty'}`}>
                                    {formData.age}
                                    <span className="custom-arrow"><i className="fa-solid fa-angle-down"></i></span>
                                </div>
                                {dropdownOpen.age && (
                                    <ul className="custom-dropdown-menu">
                                        <li className={`custom-dropdown-item ${formData.age === '20-30' ? 'selected' : ''}`} onClick={() => handleSelectOption('20-30', 'age')}> 20-30 </li>
                                        <li className={`custom-dropdown-item ${formData.age === '30-40' ? 'selected' : ''}`} onClick={() => handleSelectOption('30-40', 'age')}> 30-40 </li>
                                        <li className={`custom-dropdown-item ${formData.age === '40-50' ? 'selected' : ''}`} onClick={() => handleSelectOption('40-50', 'age')}> 40-50 </li>
                                    </ul>
                                )}
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="yearOfService">Years of Service</label>
                            <div className="custom-dropdown form-control" onClick={() => setDropdownOpen({ ...dropdownOpen, yearOfService: !dropdownOpen.yearOfService })}>
                                <div  className={`custom-dropdown-selected ${formData.yearOfService ? '' : 'empty'}`}>
                                    {formData.yearOfService}
                                    <span className="custom-arrow"><i className="fa-solid fa-angle-down"></i></span>
                                </div>
                                {dropdownOpen.yearOfService && (
                                    <ul className="custom-dropdown-menu">
                                        <li className={`custom-dropdown-item ${formData.yearOfService === '1-5' ? 'selected' : ''}`} onClick={() => handleSelectOption('1-5', 'yearOfService')}> 1-5 </li>
                                        <li className={`custom-dropdown-item ${formData.yearOfService === '5-10' ? 'selected' : ''}`} onClick={() => handleSelectOption('5-10', 'yearOfService')}> 5-10 </li>
                                        <li className={`custom-dropdown-item ${formData.yearOfService === '10+' ? 'selected' : ''}`} onClick={() => handleSelectOption('10+', 'yearOfService')}> 10+ </li>
                                    </ul>
                                )}
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="job">Job</label>
                            <div className="custom-dropdown form-control" onClick={() => setDropdownOpen({ ...dropdownOpen, job: !dropdownOpen.job })}>
                                <div className={`custom-dropdown-selected ${formData.job ? '' : 'empty'}`}>
                                    {formData.job}
                                    <span className="custom-arrow"><i className="fa-solid fa-angle-down"></i></span>
                                </div>
                                {dropdownOpen.job && (
                                    <ul className="custom-dropdown-menu">
                                        <li className={`custom-dropdown-item ${formData.job === 'Engineer' ? 'selected' : ''}`} onClick={() => handleSelectOption('Engineer', 'job')}> Engineer </li>
                                        <li className={`custom-dropdown-item ${formData.job === 'Designer' ? 'selected' : ''}`} onClick={() => handleSelectOption('Designer', 'job')}> Designer </li>
                                        <li className={`custom-dropdown-item ${formData.job === 'Manager' ? 'selected' : ''}`} onClick={() => handleSelectOption('Manager', 'job')}> Manager </li>
                                    </ul>
                                )}
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="password">Password</label>
                            <div className="password-box">
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="*****"
                                    onChange={handleInputChange}
                                    className={errors.password ? "input-error" : ""}
                                />
                                <button
                                    type="button"
                                    className="change-password-button"
                                    onClick={(e) => { onClickSaveChanges(e, 'Password') }}
                                    disabled={formData.password === ""}
                                >
                                    {isLoading === 'Password' ? <SpinLoader /> : "Change"}
                                </button>
                            </div>
                            {errors.password && <span className="error">{errors.password}</span>}

                        </div>

                        <button
                            type="submit"
                            disabled={errors.firstName || errors.lastName}
                            className='submit text-white'>{isLoading === 'Changes' ? <SpinLoader /> : "Save Changes"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
