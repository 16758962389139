import React, { useState } from "react";
import "../style/login.css";
import GoogleLogo from "../images/google.png";
import FacebookLogo from "../images/2021_Facebook_icon 1.png";
import Logo from "../images/logo.png";
import AuthAPIs from "../APIs/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import { authSuccess } from "../redux/reducers/authSlice";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { toast } from "react-hot-toast";
import SpinLoader from "./SpinLoader";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

export default function LoginCard() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const loginRes = async (data, type = '', obj = {}) => {
    setIsLoading(true);
    try {
      let mobile_token = "";
      var res = {}
      if (type === 'social') {
        res = await AuthAPIs.socialLogin(obj);
      } else {
        res = await AuthAPIs.login(data?.email, data?.password, mobile_token);
      }
      if (res) {
        setIsLoading(false);
        console.log("res.data.data.access_token === ", res.data.data.access_token)
        localStorage.setItem("accessToken", res.data.data.access_token);
        dispatch(
          authSuccess({
            user: res.data?.data.user_detail,
            accessToken: res.data.data.access_token,
          })
        );
        toast.success(res.data.detail)
        navigate(`/home/ask-leo`);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error while logging in:", error);
    }
    setIsLoading(false);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .test(
        "dot-after-at",
        'Dot should be present after "@"',
        function (value) {
          if (value) {
            const atIndex = value.indexOf("@");
            const dotIndex = value.indexOf(".", atIndex);
            return dotIndex > atIndex && dotIndex !== -1;
          }
          return false;
        }
      )
      .required("Email is required."),
    password: Yup.string().required("Password is required."),
  });

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const bearer = 'Bearer ' + tokenResponse.access_token;
        const userInfo = await axios.get(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          { headers: { Authorization: bearer } }
        );
        // console.log('userInfo', userInfo.data);
        // console.log('tokenResponse', tokenResponse);
        // console.log('tokenResponse.access_token', tokenResponse.access_token);
        handleGoogleLoginSuccess(userInfo.data);

      } catch (error) {
        console.error("Error while fetching user info:", error);
      }
    },
    onError: errorResponse => console.log(errorResponse),
  });

  const handleGoogleLoginSuccess = async (userData) => {
    const { email, name, picture, sub } = userData;
    const obj = {};
    obj['email'] = email;
    obj['name'] = name;
    obj['social_id'] = sub;
    obj['login_type'] = 'google';
    obj['user_img'] = picture;
    loginRes({}, 'social', obj);
  }

  return (
    <>
      <div className="card" id="login-card">
        <div className="right-side-component">
          <div className="logo" id="mobile-logo">
            <img src={Logo} alt="logo"></img>
            <h1>Leo.ai</h1>
          </div>

          <Formik
            onSubmit={(values) => {
              loginRes(values);
            }}
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            validateOnChange={false}
          >
            {({ handleSubmit, handleChange, values, errors, touched }) => (
              <form
                className="login-register text-start mt-20"
                noValidate
                onSubmit={handleSubmit}
              >
                <div className="form-group">
                  <label className="form-label" htmlFor="input-1">
                    Email
                  </label>
                  <input
                    className={`form-control ${touched.email && errors.email ? 'animate__animated animate__flash' : ''}`}
                    onChange={handleChange}
                    type={"email"}
                    name={"email"}
                    placeholder="Email"
                    value={values.email}
                    required
                  />
                  {touched.email && errors.email && (
                    <p className="error">{errors.email}</p>
                  )}
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="input-4">
                    Password{" "}
                  </label>
                  <div className="password-input">
                    <input
                      className={`form-control ${touched.password && errors.password ? 'animate__animated animate__flash' : ''}`}
                      onChange={handleChange}
                      type={passwordVisible ? 'text' : 'password'}
                      placeholder="Password"
                      value={values.password}
                      name={"password"}
                      required
                    />
                    {touched.password && errors.password && (
                      <p className="error">{errors.password}</p>
                    )}
                    <span className={`eye-icon ${touched.password && errors.password ? 'error-eye-icon' : ''}`} onClick={handlePasswordVisibility}>
                      {passwordVisible ? (
                        <i className="fas fa-eye-slash"></i>
                      ) : (
                        <i className="fas fa-eye"></i>
                      )}
                    </span>
                  </div>
                </div>
                <div className="login_footer form-group d-flex">
                  <a className="text-forgotpassword" href="/forgot-password">
                    Forgot Password ?
                  </a>
                </div>
                <div className="form-group" id="login">
                  <button className="btn-login" name="login">
                    {isLoading ? <SpinLoader /> : "Login"}
                  </button>
                </div>
              </form>
            )}
          </Formik>

          <div className="divider-text-center">
            <span> Or </span>
          </div>
          <div className="social-login">
            <div className="form-group" id="login">
              <button className="btn-social" type="submit" name="login" onClick={googleLogin}>
                <img src={GoogleLogo} alt="google" />
                Continue with Google
              </button>
            </div>
            {/* <div className="form-group" id="login">
              <button className="btn-social" type="submit" name="login">
                <img src={FacebookLogo} alt="facebook" />
                Continue with Facebook
              </button>
            </div> */}
          </div>
          <div className="text-register text-center">
            Don't have an Account? <a href="/signup">Sign up</a>
          </div>
        </div>
      </div>
    </>
  );
}
