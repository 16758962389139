import React from 'react';
import LeftSideComponent from '../cybercard/cybercard';
import "../../style/card.css";
import ForgotPasswordOtpCard from '../forgotpasswordotp';


export default function ForgotPasswordOtp ({ activeStep, setActiveStep }) {
  return (
    <section className="card" id='SignUp'>
      <LeftSideComponent
        title="Verify your                code"
      />
      <ForgotPasswordOtpCard activeStep={activeStep} setActiveStep={setActiveStep}/>
    </section>
  );
};