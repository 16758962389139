import React, { useEffect, useRef, useState } from "react";
import { redirect, useLocation } from 'react-router-dom';
import "../style/sideNav.css";
import UpperNav from "./uppernav";
import Swal from 'sweetalert2'
import ChatBotAPIs from 'APIs/chatbot';
import { useSelector } from "react-redux";
import { formatDate } from "Helper/helper";

export default function SideNav({ children }) {
    const location = useLocation();
    const [activeItem, setActiveItem] = useState(null);
    const [openDropdowns, setOpenDropdowns] = useState({});
    const [showDelete, setShowDelete] = useState({});
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const buttonRef = useRef(null);
    const [savedNotes, setSavedNotes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredNotes, setFilteredNotes] = useState([]);
    const { notes } = useSelector(state => state.notes)


    useEffect(() => {

        // console.log("NEW NOYTE SAVED USEEFFETC ");
        getSavedNotes();

    }, [notes])


    const handleClickOutside = (event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target)) {
            setShowDelete({});
        }
    };

    useEffect(() => {
        const pathname = location.pathname;
        setActiveItem(pathname);
    }, [location]);

    const toggleDropdown = (id) => {
        setOpenDropdowns(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const openDropdownBasedOnPath = () => {
        const pathname = location.pathname;


        let newOpenDropdowns = {};


        if (pathname.startsWith("/subscription")) {
            newOpenDropdowns[5] = true;
        } else if (pathname.startsWith("/allnotes")) {
            newOpenDropdowns[2] = true;
            if (pathname.startsWith("/allnotes/thisweek")) {
                newOpenDropdowns[3] = true;
            }
        } else if (pathname.startsWith("/home")) {
            newOpenDropdowns[1] = true;
        }

        setOpenDropdowns(newOpenDropdowns);
    };

    useEffect(() => {
        openDropdownBasedOnPath();
    }, [location]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleToggleDelete = (id) => {
        setShowDelete((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };


    const handleHamburgerClick = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleDeleteClick = (itemId, title) => {
        Swal.fire({
            title: 'Sure you want to delete?',
            text: 'Are you sure you want to delete this note?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'No, cancel',
            showCloseButton: true,
            customClass: {
                popup: 'swal-popup-custom',
                confirmButton: 'delete-note-button',
                cancelButton: 'cancel-note-delete'
            },
            target: '.main-container'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    console.log(`Deleting chat with ID: ${itemId}`);
                    await ChatBotAPIs.deleteChat(itemId);

                    setSavedNotes(prevSavedNotes => {
                        const updatedThisWeek = prevSavedNotes.thisWeek.filter(note => note._id !== itemId);
                        const updatedPast = prevSavedNotes.past.filter(note => note._id !== itemId);

                        return {
                            thisWeek: updatedThisWeek,
                            past: updatedPast
                        };
                    });

                    Swal.fire({
                        title: 'Successfully Deleted!',
                        text: ` "${title}" has been deleted from your notes`,
                        iconHtml: '<i class="fa-regular fa-circle-check"></i>' ,
                        confirmButtonText: 'OK',
                        customClass: {
                            popup: 'success-popup-custom',
                            confirmButton: 'ok-button'
                        },
                        target: '.conversation-box'
                    }).then(() => {

                        window.location.href = "/home/ask-leo";
                    });

                } catch (error) {
                    console.error("Error while deleting note:", error);
                    Swal.fire({
                        title: 'Error!',
                        text: 'Failed to delete the note. Please try again.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        customClass: {
                            popup: 'error-popup-custom',
                            confirmButton: 'ok-button'
                        },
                        target: '.conversation-box'
                    });
                }
            }
        });
    };


    useEffect(() => {
        getSavedNotes();
    }, [])

    const getSavedNotes = async () => {
        const notes = await ChatBotAPIs.getUserChatTitle();
        if (notes) {
            // console.log("Saved Notes:", notes?.data?.data);

            const allNotes = notes?.data?.data || [];
            const currentDate = new Date();


            const thisWeekNotes = [];
            const pastNotes = [];

            allNotes.forEach((note) => {
                const noteDate = new Date(note.date);
                const diffInDays = Math.floor((currentDate - noteDate) / (1000 * 60 * 60 * 24));

                if (diffInDays <= 7) {
                    thisWeekNotes.push(note);
                } else {
                    pastNotes.push(note);
                }
            });


            thisWeekNotes.sort((a, b) => new Date(b.date) - new Date(a.date));
            pastNotes.sort((a, b) => new Date(b.date) - new Date(a.date));


            setSavedNotes({ thisWeek: thisWeekNotes, past: pastNotes });
        }
    };

    const searchedNotes = async () => {
        if (searchTerm.trim() === '') {
            setFilteredNotes(savedNotes.allNotes);
            return;
        }

        const notes = await ChatBotAPIs.getUserChatTitle();
        if (notes) {
            const filtered = notes?.data?.data.filter(note =>
                note.name.toLowerCase().includes(searchTerm.toLowerCase())
            );

            console.log("filtered notes == ", filtered);
            setFilteredNotes(filtered);
        }
    };


    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        searchedNotes();
    };

    const handleSearch = () => {
        searchedNotes();
    };

    return (
        <>
            <div className={`hamburger-lines ${isSidebarOpen ? 'hide' : ''}`} onClick={handleHamburgerClick}>
                <i className="fa-solid fa-arrow-right"></i>
            </div>
            <div className={`layout ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                <div id="side-navbar" className={isSidebarOpen ? 'sidebar-visible' : ''}>
                    <div className="close-button" onClick={handleHamburgerClick}>
                        <i className="fa-regular fa-circle-xmark"></i>
                    </div>
                    <div id="navbar-container">
                        <ul className="nav-items">

                            <li className="nav-item">
                                <div className="Nav-text">
                                    <span className={`nav-text ${activeItem?.startsWith('/home') ? 'active' : ''}`}>Home</span>
                                    <button className="dropdown-btn" onClick={() => toggleDropdown(1)}>
                                        <i className={`fa-solid ${openDropdowns[1] ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                                    </button>
                                </div>
                                <ul id="dropdown-1" className={`dropdown-content ${openDropdowns[1] ? 'dropdown-active' : ''}`}>
                                    <li><a href="/home/ask-leo" className={`nav-link ${activeItem === '/home/ask-leo' ? 'active' : ''}`}>Ask Leo.ai</a></li>
                                    <li><a href="/home/news" className={`nav-link ${activeItem === '/home/news' ? 'active' : ''}`}>News</a></li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <div className="Nav-text">
                                    <span className={`nav-text ${activeItem?.startsWith('/allnotes') ? 'active' : ''}`}>All notes</span>
                                    <button className="dropdown-btn" onClick={() => toggleDropdown(2)}>
                                        <i className={`fa-solid ${openDropdowns[2] ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                                    </button>
                                </div>
                                <ul id="dropdown-2" className={`dropdown-content ${openDropdowns[2] ? 'dropdown-active' : ''}`}>
                                    <div className="wrap">
                                        <div className="search">
                                            <input
                                                type="text"
                                                className="searchTerm"
                                                placeholder="Search"
                                                value={searchTerm}
                                                onChange={handleInputChange}
                                                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                                            />
                                            <button type="submit" className="searchButton" onClick={handleSearch}>
                                                <i className="fa-solid fa-magnifying-glass fa-fade"></i>
                                            </button>
                                        </div>
                                    </div>
                                    {searchTerm === '' ? (
                                        <>
                                            <li className={`Submenu ${activeItem?.startsWith('/allnotes/thisweek') ? 'active' : ''}`}>
                                                This week
                                                <button className="dropdown-btn" onClick={() => toggleDropdown(3)}>
                                                    <i className={`fa-solid ${openDropdowns[3] ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                                                </button>
                                                <ul id="dropdown-3" className={`dropdown-content ${openDropdowns[3] ? 'dropdown-active' : ''}`}>
                                                    {savedNotes.thisWeek && savedNotes.thisWeek.length > 0 ? (
                                                        savedNotes.thisWeek.map((item) => (
                                                            <li key={item._id}>
                                                                <button className="li-button">
                                                                    <a href={`/allnotes/notes/${item._id}`}>
                                                                        <h3 className="button-text">{item.name}</h3>
                                                                        <p className="button-text">{formatDate(item.date)}</p>
                                                                    </a>
                                                                    <i
                                                                        className="fa-solid fa-ellipsis"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleToggleDelete(`${item._id}`);
                                                                        }}
                                                                        ref={buttonRef}
                                                                    ></i>
                                                                </button>
                                                                {showDelete[`${item._id}`] && (
                                                                    <div
                                                                        className="delete-button-container"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleDeleteClick(item._id, item.name);
                                                                        }}
                                                                        ref={buttonRef}
                                                                    >
                                                                        <button className="delete-button">Delete</button>
                                                                        <i className="fa-solid fa-trash-can"></i>
                                                                    </div>
                                                                )}
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <p className="no-notes">No This Week Notes Available</p>
                                                    )}
                                                </ul>
                                            </li>
                                            <li className="Submenu">
                                                Past Notes
                                                <button className="dropdown-btn" onClick={() => toggleDropdown(4)}>
                                                    <i className={`fa-solid ${openDropdowns[4] ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                                                </button>
                                                <ul id="dropdown-4" className={`dropdown-content ${openDropdowns[4] ? 'dropdown-active' : ''}`}>
                                                    {savedNotes.past && savedNotes.past.length > 0 ? (
                                                        savedNotes.past.map((item) => (
                                                            <li key={item._id}>
                                                                <button className="li-button">
                                                                    <a href={`/allnotes/notes/${item._id}`}>
                                                                        <h3 className="button-text">{item.name}</h3>
                                                                        <p className="button-text">{formatDate(item.date)}</p>
                                                                    </a>
                                                                    <i
                                                                        className="fa-solid fa-ellipsis"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            handleToggleDelete(`${item._id}`);
                                                                        }}
                                                                        ref={buttonRef}
                                                                    ></i>
                                                                </button>
                                                                {showDelete[`${item._id}`] && (
                                                                    <div
                                                                        className="delete-button-container"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleDeleteClick(item._id, item.name);
                                                                        }}
                                                                        ref={buttonRef}
                                                                    >
                                                                        <button className="delete-button">Delete</button>
                                                                        <i className="fa-solid fa-trash-can"></i>
                                                                    </div>
                                                                )}
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <p className="no-notes">No Past Notes Available</p>
                                                    )}
                                                </ul>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            {filteredNotes && filteredNotes.length > 0 ? (
                                                filteredNotes.map((item) => (
                                                    <li key={item._id}>
                                                        <button className="li-button">
                                                            <a href={`/allnotes/notes/${item._id}`}>
                                                                <h3 className="button-text">{item.name}</h3>
                                                                <p className="button-text">{formatDate(item.date)}</p>
                                                            </a>
                                                            <i
                                                                className="fa-solid fa-ellipsis"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleToggleDelete(`${item._id}`);
                                                                }}
                                                                ref={buttonRef}
                                                            ></i>
                                                        </button>
                                                        {showDelete[`${item._id}`] && (
                                                            <div
                                                                className="delete-button-container"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleDeleteClick(item._id, item.name);
                                                                }}
                                                                ref={buttonRef}
                                                            >
                                                                <button className="delete-button">Delete</button>
                                                                <i className="fa-solid fa-trash-can"></i>
                                                            </div>
                                                        )}
                                                    </li>
                                                ))
                                            ) : (
                                                <p className="no-notes">No Notes Available</p>
                                            )}
                                        </>
                                    )}
                                </ul>
                            </li>


                            <li className="nav-item">
                                <div className="Nav-text">
                                    <span className={`nav-text ${activeItem?.startsWith('/subscription') ? 'active' : ''}`}>Subscription</span>
                                    <button className="dropdown-btn" onClick={() => toggleDropdown(5)}>
                                        <i className={`fa-solid ${openDropdowns[5] ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                                    </button>
                                </div>
                                <ul id="dropdown-4" className={`dropdown-content ${openDropdowns[5] ? 'dropdown-active' : ''}`}>
                                    <li><a href="/subscription/plan" className={`nav-link ${activeItem === '/subscription/plan' ? 'active' : ''}`}>Plan</a></li>
                                </ul>
                            </li>
                        </ul>
                        <a href="/setting/profile">
                            <div className={`settings ${activeItem?.startsWith('/setting') ? 'active' : ''}`}>
                                <i className="fa-solid fa-gear fa-shake"></i> Settings
                            </div>
                        </a>
                    </div>
                </div>
                <UpperNav>{children}</UpperNav>
            </div>
        </>
    );
}
