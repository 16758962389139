// Layout.js
import React from "react";
import SideNav from "../components/sidenav"; // Adjust the import path as necessary
import "../style/sideNav.css"; // Your layout CSS

export default function Layout({ children }) {
    return (
        <>
            <SideNav>{children}</SideNav>
        </>
    );
};