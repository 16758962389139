import React, { useState } from 'react';
import { Wizard } from 'react-use-wizard';
import UserInfo from '../components/signup/signup-step1';
import JobInfo from '../components/signup/signup-step2';
import SignUpPassword from '../components/signup/signup-step3';
import SignUpImage from '../components/signup/signup-step4';
import SignUpOtp from '../components/signup/signup-step6';

export default function SignUp() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    service: '',
    age: '',
    yearOfService: '',
    job: '',
    password: '',
    imageurl: '',
    badgeNumber: '',
    otp: '',
    mobile_token:''
  });

  const updateFormData = (newData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newData, // Merge new data into formData
    }));
  };
  // console.log("all forms Data === ",formData);
  
  return (
    <Wizard>
      <UserInfo formData={formData} updateFormData={updateFormData}/>
      <JobInfo formData={formData} updateFormData={updateFormData}/>
      <SignUpPassword formData={formData} updateFormData={updateFormData}/>
      <SignUpImage formData={formData} updateFormData={updateFormData}/>
      <SignUpOtp formData={formData} updateFormData={updateFormData}/>
    </Wizard>
  );
}
