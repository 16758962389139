// LoginCard.js
import React from 'react';
import LeftSideComponent from '../cybercard/cybercard';
import "../../style/card.css";
import ImageUploadCard from '../imageuploadcard';


export default function SignUpImage ({ formData, updateFormData }) {
  return (
    <section className="card" id='SignUp'>
      <LeftSideComponent
        title1="Add your Profile"
        title2=" Photo"
      />
      <ImageUploadCard formData={formData} updateFormData={updateFormData}/>
    </section>
  );
};