import React from 'react';
import LeftSideComponent from '../cybercard/cybercard';
import "../../style/card.css";
import AccountVerifyCard from '../accountverify';


export default function Emailverify({ activeStep, setActiveStep, formData, updateFormData }) {
  return (
    <section className="card" id='SignUp'>
      <LeftSideComponent
        title="Enter your Email Address"
      />
      <AccountVerifyCard activeStep={activeStep} setActiveStep={setActiveStep} formData={formData} updateFormData={updateFormData} />
    </section>
  );
};