// LoginCard.js
import React from 'react';
import LeftSideComponent from '../cybercard/cybercard';
import "../../style/card.css";
import SignUpPasswordCard from '../password';


export default function SignUpPassword ({ formData, updateFormData }) {
  return (
    <section className="card" id='SignUp'>
      <LeftSideComponent
        title1="Create Password"
      />
      <SignUpPasswordCard formData={formData} updateFormData={updateFormData}/>
    </section>
  );
};