import "../style/uppernav.css";
import Logo from "../images/logo.png"
import profileImage from "../images//user-image-icon.webp";
import { useDispatch, useSelector } from "react-redux";
import { authSuccess } from "../redux/reducers/authSlice";
import { useNavigate } from "react-router-dom/dist";




export default function UpperNav({ children }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const logoutUser = () => {
        dispatch(authSuccess({
            userId: null,
            user: null,
            accessToken: null,
            refreshToken: null,
        }))

        navigate('/')
    }
    return (
        <>
            <div className="PageHeader">
                <div className='logo' id="mobile-logo">
                    <img src={Logo} alt="logo"></img>
                    <h1>Leo.ai</h1>
                </div>
                <nav className="upper-navbar">
                    <div className="navbar-container">
                        <div className='logo'>
                            <img src={Logo} alt="logo"></img>
                            <h1>Leo.ai</h1>
                        </div>
                        <ul className="menu-items">
                            <li className="help"><a href="/setting/support">Help <i className="fa-regular fa-circle-question"></i></a></li>
                            <li onClick={logoutUser} className="help"><a>Logout</a></li>
                            <li><a href="/setting/profile"><img src={user.user_image || profileImage} alt="Profile" /></a></li>
                        </ul>
                    </div>
                </nav>
                <div className="main-container">
                    {children}
                </div>
            </div>
        </>
    );
}