import axios from "axios";
import { store } from '../../redux/store'
import { logout } from "../../redux/reducers/authSlice";
import { toast } from "react-hot-toast";


const clearLocalStorage = () => {
    localStorage.removeItem('userId')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
}

const performLogout = () => {
    store.dispatch(logout())
    clearLocalStorage()
}

const redirectToLogin = () => {
    performLogout()
    setTimeout(() => {
        window.location = '/';
    }, 3000);
}

const consoleErrorPerformRedirection = (error, throwError, errorToast) => {
    console.error("Errrorr == =", error?.response?.data?.detail)
    if (errorToast) {
        toast.error(error?.response?.data?.detail || error.message, {
            position: "top-right",
            autoClose: 2000,
        });
    }
    if (error?.response?.status === 401) {
        redirectToLogin()
    }
    if (throwError) {
        throw error
    }
}

//Get Method
const getMethod = async (endpoint, authentication = true, data, printConsole = false) => {
    let params = {};
    let bearer_token;
    if (authentication) {
        const { auth } = store.getState();
        bearer_token = JSON.stringify(auth.accessToken) || localStorage.getItem('accessToken');

        params = {
            headers: {
                "Authorization": `Bearer ${JSON.parse(bearer_token)}`
            }
        }
    }
    if (data) {
        params.params = data
    }
    return await axios.get(endpoint, params)
        .then((res) => {
            return res
        })
        .catch((error) => {
            consoleErrorPerformRedirection(error)
        })
}
// Post Method
const postMethod = async (endpoint, authentication = true, data = null, throwError = false, errorToast = true, multipart = false) => {
    let headers = {};
    console.log("Data")
    console.log(data);

    if (authentication) {
        const { auth } = store.getState();
        var bearer_token = auth.accessToken || localStorage.getItem('accessToken');
        console.log("bearer_token ==", bearer_token)
        headers["Authorization"] = `Bearer ${bearer_token}`
    }
    if (multipart) {
        headers['content-type'] = 'multipart/form-data'
    }
    return await axios.post(endpoint, data, { headers })
        .then((res) => {
            console.log(res)
            return res
        })
        .catch((error) => {
            consoleErrorPerformRedirection(error, throwError, errorToast)
        })
}

// Delete Method
const deleteMethod = async (endpoint, authentication = true, data = null, printConsole = false) => {
    let headers = {};
    if (authentication) {
        const { auth } = store.getState();
        const bearer_token = auth.accessToken || localStorage.getItem('accessToken');
        console.log("bearer_token ==", bearer_token);
        headers["Authorization"] = `Bearer ${bearer_token}`;
    }

    console.log("Headers:", headers);
    console.log("Data to delete:", data);

    return await axios.delete(endpoint, {
        headers,
        data // Include data inside the configuration object
    })
        .then((res) => {
            console.log("Delete response:", res);
            return res;
        })
        .catch((error) => {
            consoleErrorPerformRedirection(error);
        });
};


//Patch Method
const patchMethod = async (endpoint, authentication = true, data = null, printConsole = false, multipart = false) => {
    let headers = {};
    console.log("Data")
    console.log(data);

    if (authentication) {
        const { auth } = store.getState();
        var bearer_token = auth.accessToken || localStorage.getItem('accessToken');
        headers["Authorization"] = `Bearer ${JSON.parse(bearer_token)}`
    }
    if (multipart) {
        headers['content-type'] = 'multipart/form-data'
    }
    return await axios.patch(endpoint, data, { headers })
        .then((res) => {
            console.log(res)
            return res
        })
        .catch((error) => {
            consoleErrorPerformRedirection(error)
        })
}


export default {
    getMethod,
    postMethod,
    deleteMethod,
    patchMethod
};