let apiUrl = process.env.REACT_APP_DEV_API_URL
console.log('This is a test deployment ', apiUrl)
switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'local':
        apiUrl = process.env.REACT_APP_LOCAL_API_URL;
        break;
    case 'staging':
        apiUrl = process.env.REACT_APP_STG_API_URL;
        break;
    case 'dev':
        apiUrl = process.env.REACT_APP_DEV_API_URL;
        break;
    case 'live':
        apiUrl = process.env.REACT_APP_DEV_API_URL;
        break;
    default:
        apiUrl = process.env.REACT_APP_DEV_API_URL;
        break;
}
export const API_URL = apiUrl;

export const ENDPOINT = {

    auth: {
        login: `${API_URL}/api/v1/auth/login`,
        signup: `${API_URL}/api/v1/auth/signup`,
        uploodProfile: `${API_URL}/api/v1/auth/uploadProfileImage`,
        isUserExist: `${API_URL}/api/v1/auth/isUserExists`,
        socialLogin: `${API_URL}/api/v1/auth/login-with-socail-account`,
        forgotPassword: `${API_URL}/api/v1/auth/forgotPassword`,
        resetPassword: `${API_URL}/api/v1/auth/resetPassword`,
    },

    user: {
        updateUser: `${API_URL}/api/v1/users/updateUser`
    },

    chatBot: {
        getUserChatTitle: `${API_URL}/api/v1/users/getUserChatTitle`,
        createChat: `${API_URL}/api/v1/users/chatWithBot`,
        updateChat: `${API_URL}/api/v1/users/updateChatDetail`,
        getChatHistory: `${API_URL}/api/v1/users/getChatBotHistory`,
        deleteChat: `${API_URL}/api/v1/users/deleteNote`,
    },

    support:{
        getConversations: `${API_URL}/api/v1/support/conversations`,
        createConversation: `${API_URL}/api/v1/support/conversations`,
        getMessages: `${API_URL}/api/v1/support/conversations/messages`
    },
    guidelines: {
        aboutUs: `${API_URL}/api/v1/admin/guidelines`
    }


};