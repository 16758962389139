import ProfileNav from "components/profilenav";
import "../style/support.css"
import { useEffect, useState } from "react";
import SupportAPIs from 'APIs/support';
import { formatDate } from "Helper/helper";
import Swal from 'sweetalert2';

export default function Support() {
    const [supportTickets, setSupportTickets] = useState([]);

    useEffect(() => {
        getConversations();
    }, [])

    const getConversations = async () => {
        const res = await SupportAPIs.getConversations();
        if (res) {
            console.log("Response of the conversations tiicjkees = == ", res.data?.conversations);
            setSupportTickets(res.data?.conversations);
        }
    }

    const createSupportTicket = async (query) => {
        console.log("Creating suppoort ticket ==", query);
        const res = await SupportAPIs.createConversation({ message: query });
        if (res) {
            console.log("support ticket created successfully -===", res.data);
            getConversations();
            return query
        }
    }

    function handleAddTicket() {
        Swal.fire({
            title: 'Support Ticket',
            text: 'Do you want to create a new ticket?',
            input: 'text',
            inputPlaceholder: 'Query',
            showCancelButton: true,
            confirmButtonText: 'Yes, Confirm',
            cancelButtonText: 'No, Cancel',
            showCloseButton: true,
            customClass: {
                confirmButton: 'new-ticket-button',
                cancelButton: 'cancel-ticket-button'
            },
            target: ".profile",
            preConfirm: async (query) => {
                if (!query) {
                    Swal.showValidationMessage('Please enter your Query.');
                    Swal.hideLoading();
                    Swal.disableButtons();
                    return;
                }
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    console.log("RESULTES ==", result)
                    await createSupportTicket(result.value)
                    Swal.showLoading();
                    if (result.value) {
                        Swal.fire({
                            title: 'Successfull!',
                            text: `"${result.value}" saved as support ticket.`,
                            icon: 'success',
                            iconHtml: '<i class="fa-regular fa-circle-check"></i>',
                            showCloseButton: true,
                            confirmButtonText: 'ok',
                            customClass: {
                                icon: 'success-icon',
                                confirmButton: 'ok-button'
                            },
                            target: '.profile',
                        });

                    }
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error?.response?.data?.detail,
                        // footer: '<a href="#">Why do I have this issue?</a>'s
                    });
                }
            }

        });
    }

    return (
        <div className="profile">
            <ProfileNav />
            <div className='support'>
                <div className="row">
                    <label>Support</label>
                    <div className="newTicket">
                        <button onClick={handleAddTicket}>Add Ticket</button>
                    </div>
                </div>
                <div className="ticketList">
                    {/* <a href="/setting/support/1">
                        <div className="ticket active">
                            <div className="row">
                                <div className="ticketId">
                                    <div className="circle"></div>
                                    <label>1276321786</label>
                                </div>
                                <div className="status">
                                    <button className="pending">Pending</button>
                                </div>
                            </div>
                            <div className="time">September 23, 2024 09:23am</div>
                            <div className="description">
                                <p>Amet minim mollit non deserunt ullamco est sit consequat duialiqua dolor do amet sint. Velit officia consequat duis enim velit mollit. </p>
                            </div>
                        </div>
                    </a> */}

                    {supportTickets && supportTickets[0] ?
                        supportTickets.map((item) => (
                            <a key={item?._id} href={`/setting/support/${item?._id}`}>
                                <div className="ticket">
                                    <div className="row">
                                        <div className="ticketId">
                                            <div className="circle"></div>
                                            <label>{item?._id}</label>
                                        </div>
                                        <div className="status">
                                            <button className={item?.status === "completed" ? "completed" : 'pending'}>{item?.status}</button>
                                        </div>
                                    </div>
                                    <div className="time">{formatDate(item?.updated_at)}</div>
                                    <div className="description">
                                        <p>{item?.last_message}</p>
                                    </div>
                                </div>
                            </a>
                        )) : <p>No Support Ticket Available</p>
                    }
                </div>
            </div>
        </div >
    );
}