import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // For validation schema
import Logo from '../images/logo.png';
import '../style/login.css';
import { useWizard } from 'react-use-wizard';

export default function SignUpPasswordCard({ formData, updateFormData }) {
    const { previousStep, nextStep } = useWizard();
    const [passwordVisible, setPasswordVisible] = useState(false);

    const handlePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    // Formik setup with validation schema
    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required('Password is required')
                .min(8, 'Password must be at least 8 characters'),
            confirmPassword: Yup.string()
                .required('Please confirm your password')
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        }),
        onSubmit: (values) => {
            updateFormData({
                password: values.password,
            });
            nextStep(); // Move to the next step
        },
    });

    return (
        <>
            <div className="card" id="login-card">
                <div className="Form">
                    <div className="logo" id="mobile-logo">
                        <img src={Logo} alt="logo"></img>
                        <h1>Leo.ai</h1>
                    </div>
                    <div className="Backbutton" onClick={previousStep}>
                        <i className="fa-solid fa-angle-left"></i>
                        <h1>Back</h1>
                    </div>
                    <div className="right-side-component" id="password">
                        <form className="login-register text-start mt-20" onSubmit={formik.handleSubmit}>
                            {/* Password field */}
                            <div className="form-group">
                                <label className="form-label" htmlFor="password">Password</label>
                                <div className="password-input">
                                    <input
                                        className={`form-control ${formik.touched.password && formik.errors.password ? 'animate__animated animate__flash' : ''}`}
                                        id="password"
                                        type={passwordVisible ? 'text' : 'password'}
                                        name="password"
                                        placeholder="************"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                    />
                                    <span className="eye-icon" onClick={handlePasswordVisibility}>
                                        {passwordVisible ? (
                                            <i className="fas fa-eye-slash"></i>
                                        ) : (
                                            <i className="fas fa-eye"></i>
                                        )}
                                    </span>
                                </div>
                                {formik.touched.password && formik.errors.password ? (
                                    <div className="error">{formik.errors.password}</div>
                                ) : null}
                            </div>

                            {/* Confirm Password field */}
                            <div className="form-group">
                                <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                                <div className="password-input">
                                    <input
                                        className={`form-control ${formik.touched.confirmPassword && formik.errors.confirmPassword ? 'animate__animated animate__flash' : ''}`}
                                        id="confirmPassword"
                                        type={passwordVisible ? 'text' : 'password'}
                                        name="confirmPassword"
                                        placeholder="************"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.confirmPassword}
                                    />
                                    <span className="eye-icon" onClick={handlePasswordVisibility}>
                                        {passwordVisible ? (
                                            <i className="fas fa-eye-slash"></i>
                                        ) : (
                                            <i className="fas fa-eye"></i>
                                        )}
                                    </span>
                                </div>
                                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                    <div className="error">{formik.errors.confirmPassword}</div>
                                ) : null}
                            </div>

                            {/* Continue button */}
                            <div className="form-group" id="login">
                                <button type="submit" className="btn-login">Continue</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
