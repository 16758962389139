import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import Logo from '../images/logo.png';
import '../style/login.css';
import { useWizard } from 'react-use-wizard';

export default function ForgotPasswordOtpCard({ activeStep, setActiveStep }) {
    const { nextStep ,goToStep } = useWizard();
    const [otp, setOtp] = useState('');
    const [errors , setErrors ] = useState('');

    
    const handleChange = (otpValue) => {
        setOtp(otpValue);
        console.log("OTP Value == ", otpValue);
    };

    const renderInput = (props) => (
        <input {...props} type="number" className="otp-input" placeholder="" /> 
    );

    const handleBack = () => {
        if (activeStep === 1) {
            goToStep(0);
        } 
        // else if (activeStep === 2) {
        //     goToStep(0);
        // }
      };

      const handleSubmit = ()=>{
        if(!otp){
            setErrors('Code is required.')
        }else{
            nextStep();
            // setErrors('Invalid Code.')
        }
      }

    return (
        <>
            <div className="card otp-card" id="login-card">
                <div className="logo" id="mobile-logo">
                    <img src={Logo} alt="logo" />
                    <h1>Leo.ai</h1>
                </div>

                <div className="Backbutton" onClick={handleBack}>
                    <i className="fa-solid fa-angle-left"></i>
                    <h1>Back</h1>
                </div>

                <div className="right-side-component otp" id='password'>
                    <form className="login-register text-start mt-20" action="#">
                        <div className="form-group badge-inputs">
                            <OtpInput
                                className="otp"
                                value={otp}
                                onChange={handleChange}
                                numInputs={6} 
                                shouldAutoFocus={true} 
                                inputStyle="otp" 
                                containerStyle="otp-container" 
                                renderInput={renderInput} 
                                inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }}
                            />
                        </div>

                        <div className="form-group" id="login">
                        <p style={{color:'red', textAlign:'left'}}>{errors}</p>
                            <button
                                className="btn-login"
                                name="login"
                                type="button"
                                onClick={handleSubmit}                              
                            >
                                Continue
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
