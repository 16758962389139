import React, { useState } from 'react';
import { Wizard } from 'react-use-wizard';
import Emailverify from '../components/forgotpassword/forgotpassword-step1';
// import Phoneverify from '../components/forgotpassword/forgotpassword-step2';
import ForgotPasswordOtp from '../components/forgotpassword/forgotpassword-step3';
import UpdatePassword from '../components/forgotpassword/forgotpassword-step4';

export default function ForgotPassword() {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    c_password: '',
  });

  const updateFormData = (newData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newData, // Merge new data into formData
    }));
  };

  return (
    <Wizard>
      <Emailverify activeStep={activeStep} setActiveStep={setActiveStep} formData={formData} updateFormData={updateFormData} />
      {/* <Phoneverify activeStep={activeStep} setActiveStep={setActiveStep}/> */}
      <ForgotPasswordOtp activeStep={activeStep} setActiveStep={setActiveStep} formData={formData} updateFormData={updateFormData} />
      <UpdatePassword formData={formData} updateFormData={updateFormData} />
    </Wizard>
  );
}