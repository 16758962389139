import React, { useEffect, useRef, useState } from 'react';
import Notes from '../components/notes/notes';
import '../style/askLeo.css';
import microphone from '../images/microphone.png';
import { useSelector } from "react-redux";
import ChatBotAPIs from 'APIs/chatbot';
import { Oval } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { newNoteSaved } from '../redux/reducers/newSaveNotes';

export default function AskLeo() {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState();
    const { accessToken } = useSelector((state) => state.auth);
    const [prompts, setPrompts] = useState([]);
    const [chatId, setChatId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const socketRef = useRef(null);
    const dispatch = useDispatch();

    const getUserChatTitle = async () => {
        const res = await ChatBotAPIs.getUserChatTitle();
        if (res) {
            console.log("getUserChatTitle response  - ", res.data?.data[0]._id);
            setChatId(res.data?.data[0]._id);
        }
    }

    useEffect(() => {
        getUserChatTitle();
    }, [])

    const handleSend = (userText) => {
        setIsLoading(true);
        console.log("Handle send function call --", accessToken)
        if (userText.trim() !== '') {
            setPrompts([...prompts, userText]);
        }
        setMessage('');

        const socket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}/api/v1/socket/ws?Authorization=__token_start__${encodeURIComponent(accessToken)}__token_end__%26__chat_id_start__${encodeURIComponent(chatId)}__chat_id_end__&`);
        socketRef.current = socket;
        socket.onopen = () => {
            console.log('Connected to server');
            socket.send(message);
        };

        const date = new Date().toISOString();
        setMessages([
            ...messages,
            { text: message, by_user: true, date, evaluation: '' },
        ])
        let responseMessage = ''
        let responseDate = null
        socket.onmessage = (event) => {
            if (!responseMessage) {
                responseDate = new Date()
            }
            console.log('Received message from server:', event.data);
            responseMessage += event.data
            setMessages([
                ...messages,
                { text: message, by_user: true, date, evaluation: '' },
                { text: responseMessage, by_user: false, date: responseDate, evaluation: '' }
            ])
        };

        socket.onclose = () => {
            setIsLoading(false);
            console.log('Connection closed');
        };

        socket.onerror = (error) => {
            console.log('Connection Error', error);
        };
    };

    const handleCloseSocket = () => {
        console.log("Close Button clicked");
        if (socketRef.current) {
            socketRef.current.close(); // Close the WebSocket connection
            console.log('Socket connection manually closed');
            setMessage('');
            setIsLoading(false);
        }
    };



    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSend(message);
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        console.log(file);
    };

    const handleMessageInput = (e) => {
        setMessage(e.target.value)
    }

    function handleSaveClick() {
        Swal.fire({
            title: 'Save as Note',
            text: 'Are you sure you want to save this as your note?',
            input: 'text',
            inputPlaceholder: 'Inquiry001',
            showCancelButton: true,
            confirmButtonText: 'Yes, Confirm',
            cancelButtonText: 'No, Cancel',
            showCloseButton: true,
            preConfirm: async (noteName) => {
                if (!noteName) {
                    Swal.showValidationMessage('Please enter a Note name');
                    Swal.hideLoading();
                    Swal.disableButtons();
                    return;
                }
                Swal.showLoading();
                return await createAndUpdateChat(noteName)
                // // Simulate a 2-second delay
                // return new Promise((resolve) => {
                //     setTimeout(() => {
                //         resolve(noteName);
                //     }, 2000);
                // });
            },
            target: '.notes-section',
            customClass: {
                popup: 'swal-popup-custom'
            }
        }).then((result) => {
            console.log("RESULTES ==", result)
            if (result.value) {
                Swal.fire({
                    title: 'Successfully saved!',
                    text: `"${result.value}" saved as your note`,
                    icon: 'success',
                    iconHtml: '<i class="fa-regular fa-circle-check"></i>', // Corrected to use 'class'
                    showCloseButton: true,
                    confirmButtonText: 'Return',
                    customClass: {
                        icon: 'success-icon',
                    },
                    target: '.notes-section',
                });

            }
        });
    }

    const createAndUpdateChat = async (title) => {

        try {
            /**
         * Creating New Chat First
         */
            const chat = await ChatBotAPIs.createChat({ chat: title });
            if (chat) {
                console.log("Chat Created ===", chat?.data?.data.chat_master_id);
                let chatId = chat?.data?.data.chat_master_id;
                if (chatId) {
                    /**
                     * Updating the Chat with Messages Arrray
                     */
                    const updateChat = await ChatBotAPIs.updateChat({ chat_id: chatId, chat: messages });
                    if (updateChat) {
                        console.log("Chat updaated Successfullyy == ", updateChat.data);
                        dispatch(
                            newNoteSaved({
                                notes: updateChat.data
                            })
                        );
                        return title
                    }
                }
            }
        } catch (error) {
            console.log(error?.response?.data?.detail)
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error?.response?.data?.detail,
                footer: '<a href="#">Why do I have this issue?</a>'
            });
        }

    }

    const newChat = () => {
        Swal.fire({
            title: 'Alert !',
            text: 'Do you want to start a new conversation without saving the previous one?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Save as Note',
            showCloseButton: true,
            customClass: {
                popup: 'swal-popup-custom',
                confirmButton: 'new-conversation-button',
                cancelButton: 'save-note-button'
            },
            target: '.conversation-box'
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.cancel) {
                handleSaveClick();
            } else if (result.value) {
                setMessage('');
                setMessages([]);
            }
        });
    }

    return (
        <>
            {console.log("Messages  -- ", messages)}
            <div className="ask-leo-container">
                <div className="conversation-box">
                    {messages.length > 0 ? (
                        messages.map((item, index) => (
                            <Notes
                                chatArray={messages}
                                key={index}
                                message={item.text}
                                by_user={item.by_user}
                                saveLink={index === messages.length - 1}
                                shareHref="#"
                                copyHref="#"
                                saveHref="#"
                            />
                        ))
                    ) : (
                        <div className="centered-text">
                            <h1>Leo.Ai</h1>
                        </div>
                    )}
                </div>

                <div className="message-input-box">
                    {messages.length > 0 && (
                        <button className='newChatBtn' onClick={newChat}>
                            <i class="fa-solid fa-plus"></i>
                        </button>
                    )}
                    {/* <a>
                        <button onClick={() => document.getElementById('fileUpload').click()}>
                            <i className="fa-solid fa-paperclip"></i>
                        </button>
                        <input
                            type="file"
                            id="fileUpload"
                            style={{ display: 'none' }}
                            onChange={(e) => handleFileUpload(e)}
                        />
                    </a> */}
                    {isLoading && <Oval
                        visible={true}
                        height="22"
                        width="22"
                        color="#000000"
                        ariaLabel="oval-loading"
                        strokeWidth="5"
                        secondaryColor="#c7c7c7"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />}

                    <textarea
                        type="text"
                        placeholder={isLoading ? "Generating message.." : "How can I assist you?"}
                        id="user-input"
                        onChange={handleMessageInput}
                        onKeyDown={handleKeyPress}
                        value={message}
                        disabled={isLoading}
                    />
                    {/* <a>
                        <button>
                            <i><img src={microphone} alt='microphone'></img></i>
                        </button>
                    </a> */}
                    {isLoading ? <i onClick={handleCloseSocket} className="fa-solid fa-circle-stop"></i> : <a>
                        <button disabled={message?.length === 0} onClick={() => handleSend(document.getElementById('user-input').value)}>
                            <i className="fa-solid fa-paper-plane"></i>
                        </button>
                    </a>}
                </div>
            </div>
        </>
    );
}
