// LeftSideComponent.js
import React from 'react';
import '../../style/cyberCard.css';
import Logo from '../../images/logo.png';


export default function LeftSideComponent({ title1, className, paragraph , title2 }) {
  return (
    <div className="left-side">
      <div className='logo'>
        <img src={Logo} alt="logo"></img>
        <h1>Leo.ai</h1>
      </div>
      <div className="text-container">
        <h2 className= "title" >{title1}</h2>
        <h2 className= "title" >{title2}</h2>
        <p>{[paragraph]}</p>
      </div>
    </div>
  );
};