import ProfileNav from "components/profilenav";
import { useEffect, useState } from "react";
import AuthAPIs from '../APIs/app/index';
import '../style/profileNav.css';

const Guideline = ({ guidelineType }) => {
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchGuidelines = async () => {
        try {
            const params = { guideline_type: guidelineType };
            const response = await AuthAPIs.aboutUs(params);
            setContent(response?.data?.content || 'No content available.');
        } catch (err) {
            console.error('Error fetching terms and conditions:', err);
            setError('Failed to load content.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGuidelines();
    }, []);

    return (
        <div className="profile">
            <ProfileNav />
            <div className={`faq ${loading ? 'loading' : ''}`}>
                <div className="faq-container">
                    <div className="faq-content">
                        {loading ? (
                            <div class="loader"></div>
                        ) : error ? (
                            <div>{error}</div>
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: content }} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Guideline;