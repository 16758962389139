import React, { useState } from "react";
import OtpInput from "react-otp-input";
import Logo from "../images/logo.png";
import "../style/login.css";
import { useWizard } from "react-use-wizard";
import AuthAPIs from "APIs/auth";
import SpinLoader from "./SpinLoader";
import Swal from "sweetalert2";

export default function SignUpOtpCard({ formData }) {
  const { previousStep } = useWizard();
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState('');

  // Handle OTP change
  const handleChange = (otpValue) => {
    setOtp(otpValue);
    if (otpValue.length > 0) {
      setErrors('');
    }
    console.log("otp Value == ", otpValue);
  };

  const renderInput = (props) => (
    <input {...props} type="number" className="otp-input" placeholder="" /> // Set input type to number
  );

  const handleSignup = async () => {
    try {
      if (!otp) {
        setErrors('Code is required.');
        return;
      }
      setIsLoading(true);
      console.log("form data in otp screen = ", formData);
      let { imageurl, ...rest } = formData;
      console.log("Image url ", imageurl);
      rest["user_image"] = await uploodProfileImage(imageurl);
      rest["profileCompleted"] = true

      console.log("Rest on signup =", rest);
      const register = await AuthAPIs.signup(rest);
      if (register) {
        console.log("User Registered Succcessfully ", register?.data);
        Swal.fire({
          title: 'Registered Successfully',
          text: 'Account created',
          icon: 'success',
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonText: 'Login',
          customClass: {
            confirmButton: 'signupswal2-confirm',
          },
          target: '#SignUp'
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            setIsLoading(false);
            window.location.replace('/');
          } else {
            setIsLoading(false);
          }
        })
      }
    } catch (error) {
      console.log("Error in handle signup =", error);
      setIsLoading(false)
    }

  };

  const uploodProfileImage = async (img) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", img);
    let imgRes = await AuthAPIs.uploodProfileImage(formData);
    if (imgRes) {
      console.log("Image upload Suucessfully =", imgRes?.data?.user_img);
      return imgRes?.data?.user_img;
    } else {
      return "";
    }
  };


  return (
    <>
      <div className="card otp-card" id="login-card">
        <div className="logo" id="mobile-logo">
          <img src={Logo} alt="logo" />
          <h1>Leo.ai</h1>
        </div>
        <div className="Backbutton" onClick={previousStep}>
          <i className="fa-solid fa-angle-left"></i>
          <h1>Back</h1>
        </div>
        <div className="right-side-component" id="password">
          <form className="login-register text-start mt-20" action="#">
            <div className="form-group badge-inputs">
              <OtpInput
                className="otp"
                value={otp}
                onChange={handleChange}
                numInputs={6}
                shouldAutoFocus={true}
                inputStyle="otp"
                containerStyle="otp-container"
                renderInput={renderInput}
                inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }} // Restrict to numbers only
              />
            </div>
            <div className="form-group" id="login">
              <p style={{ color: 'red', textAlign: 'left' }}>{errors}</p>
              <button
                className="btn-login"
                name="login"
                type="button"
                onClick={handleSignup}
              >
                {isLoading ? <SpinLoader /> : "Continue"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
