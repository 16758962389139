import { useState } from 'react';
import Logo from '../images/logo.png';
import '../style/login.css';
import * as Yup from 'yup';
import { useWizard } from 'react-use-wizard';
import { useFormik } from 'formik';
import AuthAPIs from "APIs/auth";
import Swal from 'sweetalert2';

export default function UpdatePasswordCard({ formData, updateFormData }) {
    const { previousStep, nextStep } = useWizard();
    const [passwordVisible, setPasswordVisible] = useState(false);

    const handlePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    // Formik setup with validation schema
    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required('Password is required')
                .min(8, 'Password must be at least 8 characters'),
            confirmPassword: Yup.string()
                .required('Please confirm your password')
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        }),
        onSubmit: async (values) => {
            console.log("Form data:", formData);
            const data = {
                email: formData.email,
                password: values.password,
                c_password: values.password
            }

            console.log("Updated formData:", data);
            try {
                const response = await AuthAPIs.resetPassword(data);
                // console.log("Response:", response);
                // console.log("Response Data:", formData);

                Swal.fire({
                    icon: 'success',
                    title: 'Password Reset Successful',
                    text: response.data?.message || 'Your password has been reset successfully!',
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Redirect to "/"
                        window.location.href = '/';
                    }
                });
            } catch (error) {
                const errorMessage = error.response?.data?.message || 'Failed to reset the password. Please try again.';
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorMessage,
                    buttons: [
                        {
                            text: 'OK',
                            value: true,
                            visible: true,
                            className: '',
                            closeModal: true,
                        },
                    ],
                });
            }
        },
    });

    return (
        <>
            <div className="card otp-card" id="login-card">
                <div className='logo' id="mobile-logo">
                    <img src={Logo} alt="logo"></img>
                    <h1>Leo.ai</h1>
                </div>
                <div className="Backbutton" onClick={previousStep}>
                    <i className="fa-solid fa-angle-left"></i>
                    <h1>Back</h1>
                </div>
                <div className="right-side-component otp" id='password'>
                    <form className="login-register text-start mt-20" onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-4">Password </label>
                            <div className="password-input">
                                <input className={`form-control ${formik.touched.password && formik.errors.password ? 'animate__animated animate__flash' : ''}`} id="input-4" type={passwordVisible ? 'text' : 'password'} required="" name="password" placeholder="************"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password} />
                                <span className="eye-icon" onClick={handlePasswordVisibility}>
                                    {passwordVisible ? (
                                        <i className="fas fa-eye-slash"></i>
                                    ) : (
                                        <i className="fas fa-eye"></i>
                                    )}
                                </span>
                            </div>
                            {formik.touched.password && formik.errors.password ? (
                                <div className="error">{formik.errors.password}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="input-4">Confirm Password </label>
                            <div className="password-input">
                                <input className={`form-control ${formik.touched.confirmPassword && formik.errors.confirmPassword ? 'animate__animated animate__flash' : ''}`} id="input-4" type={passwordVisible ? 'text' : 'password'} required="" name="confirmPassword" placeholder="************" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirmPassword} />
                                <span className="eye-icon" onClick={handlePasswordVisibility}>
                                    {passwordVisible ? (
                                        <i className="fas fa-eye-slash"></i>
                                    ) : (
                                        <i className="fas fa-eye"></i>
                                    )}
                                </span>
                            </div>
                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                <div className="error">{formik.errors.confirmPassword}</div>
                            ) : null}
                        </div>
                        <div className="form-group" id="login">
                            <button className="btn-login" name="login" type="submit">Continue</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}