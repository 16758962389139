// NewPassword.js
import React from 'react';
import LeftSideComponent from '../cybercard/cybercard';
import "../../style/card.css";
import UpdatePasswordCard from '../updatepassword';


export default function UpdatePassword({ formData, updateFormData }) {
  return (
    <section className="card" id='SignUp'>
      <LeftSideComponent
        title="Create New Password"
      />
      <UpdatePasswordCard formData={formData} updateFormData={updateFormData} />
    </section>
  );
};