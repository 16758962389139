import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Notes from "components/notes/notes";
import ChatBotAPIs from '../APIs/chatbot/index';
import "../style/loder.css";

export default function NotesHistory() {
    const { chatId } = useParams();
    console.log("Chat ID from params:", chatId);

    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        console.log("Chat ID:", chatId);

        if (!chatId) {
            setError("Invalid or missing chat ID.");
            setLoading(false);
            return;
        }

        const fetchChatHistory = async () => {
            try {

                const response = await ChatBotAPIs.getChatHistory(chatId);

                if (response?.data?.status_code === 202) {
                    if (response.data.data.length > 0) {
                        const chatMessages = response.data.data[0].text.map(item => ({
                            text: item.text,
                            by_user: item.by_user,
                        }));
                        setMessages(chatMessages);
                    } else {
                        setError("No notes found for the given ID.");
                    }
                } else {
                    setError(response?.data?.detail || "pass correct chat id.");
                }
            } catch (err) {
                console.log("Error fetching chat history:", err);
                setError("Failed to fetch chat history.");
            } finally {
                setLoading(false);
            }
        };

        fetchChatHistory();
    }, [chatId]);

    return (
        <div className="notes-history-container">
            {loading ? (
                <div className="loader"></div>
            ) : error ? (
                <p className="error-message">{error}</p>
            ) : (
                messages.map((item, index) => (
                    <Notes
                        chatArray={messages}
                        key={index}
                        message={item.text}
                        by_user={item.by_user}
                        chatId={chatId}
                    />
                ))
            )}
        </div>
    );
}
