// LoginCard.js
import React from 'react';
import LeftSideComponent from '../components/cybercard/cybercard';
import "../style/card.css";
import LoginCard from '../components/logincard';


export default function Login() {
  return (
    <section className="card" >
      <LeftSideComponent
        title1="Welcome to"
        title2="Leo.ai"
        paragraph="Enter your email and password to log in "
      />
      <LoginCard />
    </section>
  );
};