// LoginCard.js
import React from "react";
import LeftSideComponent from "../cybercard/cybercard";
import "../../style/card.css";
import SignUpOtpCard from "../signupotp";

export default function SignUpOtp({ formData }) {
  return (
    <section className="card" id="SignUp">
      <LeftSideComponent
        title1="Verify your"
        title2="code"
        paragraph="Sent to your email"
      />
      <SignUpOtpCard formData={formData} />
    </section>
  );
}
