import React, { useState } from 'react';
export default function SubscriptionPlan() {
    const [activePlan, setActivePlan] = useState('yearly');

    // Plans data
    const plans = {
        monthly: {
            title: "Starter",
            price: "$20",
            duration: "/month",
            description: "Get our starter plan for monthly services."
        },
        yearly: {
            title: "Popular",
            price: "$200",
            duration: "/year",
            description: "Get our popular plan with yearly services."
        }
    };

    const handlePlanChange = (plan) => {
        setActivePlan(plan);
    };
    return (
        <>
            <div className="container subscription">
                <div className="subscription-buttons">
                    <button className="button-box">
                        <button
                            className={`monthly ${activePlan === 'monthly' ? 'button-active' : ''}`}
                            onClick={() => handlePlanChange('monthly')}
                        >
                            Monthly
                        </button>
                        <button
                            className={`yearly ${activePlan === 'yearly' ? 'button-active' : ''}`}
                            onClick={() => handlePlanChange('yearly')}
                        >
                            Yearly
                        </button>
                    </button>
                </div>
                <div className="content">
                    <div className="heading">
                        <h2>Select plan that help’s grow</h2>
                    </div>
                    <div className="paragraph">
                        <p>Read all plans or contact us, we will guide you better</p>
                    </div>
                </div>
                <div className="subscription-box">
                    <h1 className="title">{plans[activePlan].title}</h1>
                    <p className="amount">
                        <span className="currency">{plans[activePlan].price}</span>
                        <span className="duration">{plans[activePlan].duration}</span>
                    </p>
                    <p className="description">
                        {plans[activePlan].description}
                    </p>
                    <div className="option-box">
                        <div className="options">
                            <input type="checkbox" checked />
                            <h2 className="text">Full LEO AI functionality including unlimited queries</h2>
                        </div>
                        <div className="options">
                            <input type="checkbox" checked />
                            <h2 className="text">Notes section to save and reference past queries</h2>
                        </div>
                        <div className="options">
                            <input type="checkbox" checked />
                            <h2 className="text">Updates on new products</h2>
                        </div>
                    </div>
                    <button>Choose Plan</button>
                </div>
            </div>
        </>
    );
}