import { useState } from 'react';
import Logo from '../images/logo.png';
import '../style/login.css'
import { useWizard } from 'react-use-wizard';
import { Formik } from 'formik';
import * as Yup from "yup";
import AuthAPIs from "../APIs/auth/index";

export default function AccountVerifyCard({ activeStep, setActiveStep, formData, updateFormData }) {
    const { goToStep } = useWizard();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const Emailverify = () => {
        if (email) {

        } else {
            setError('Email is required.');
        }
    };
    // const Phoneverify = () => {
    //     setActiveStep(1);
    //     goToStep(1);
    // };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email address")
            .test(
                "dot-after-at",
                'Dot should be present after "@"',
                function (value) {
                    if (value) {
                        const atIndex = value.indexOf("@");
                        const dotIndex = value.indexOf(".", atIndex);
                        return dotIndex > atIndex && dotIndex !== -1;
                    }
                    return false;
                }
            )
            .required("Email is required."),
    });


    const handleSubmit = async (values) => {
        try {
            const response = await AuthAPIs.forgotPassword({ email: values.email });
            if (response && response.data) {
                console.log("Response:", response);
                const email = values.email;
                updateFormData({ email });
                setActiveStep(1);
                goToStep(1);
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.log("error.response: == ", error.response);
                setError("User Doesn't Exist");
            } else {
                setError("An error occurred. Please try again.");
            }
        }
    };



    return (
        <>
            <div className="card otp-card" id="login-card">
                <div className='logo' id="mobile-logo">
                    <img src={Logo} alt="logo"></img>
                    <h1>Leo.ai</h1>
                </div>
                <div className="Backbutton" onClick={() => window.location.href = '/'}>
                    <i className="fa-solid fa-angle-left"></i>
                    <h1>Back</h1>
                </div>
                <div className="right-side-component verify-account" id='password'>
                    <Formik
                        initialValues={{ email: '' }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            handleSubmit(values);
                        }}
                        validateOnChange={false}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <form className=" text-start mt-20" noValidate onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="input-1">Email Address</label>
                                    <input
                                        className={`form-control ${(touched.email && errors.email) || error ? 'animate__animated animate__flash' : ''}`}
                                        onChange={handleChange}
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        placeholder="StevenJob@gmail.com"
                                        required
                                        isInvalid={!!errors.email}
                                    />
                                    {/* <input className="form-control" id="input-1" onChange={handleChange} value={values.email} isInvalid={!!errors.email}
                                        type="text" required name="Last name" placeholder="StevenJob@gmail.com" /> */}
                                    <p className="error">{errors.email || error}</p>
                                </div>
                                <div className="form-group" id="login">
                                    <button type='submit' className="btn-login" name="login">Verify Account</button>
                                </div>
                                {console.log("error  - ", errors)}
                            </form>
                        )}
                    </Formik>
                    {/* <div className="text-register text-center" onClick={(e) => { e.preventDefault(); Phoneverify(); }}><a href="!" >Use phone number instead</a></div> */}
                </div>
            </div >
        </>
    );
}