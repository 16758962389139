import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import ProfileNav from 'components/profilenav';
import '../../style/profileNav.css';
import '../../style/askLeo.css';
import '../../style/support.css';
import SuppotAPIs from 'APIs/support';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function SupportMessage() {
    const { conversation_id } = useParams();
    const [messagesArray, setMessagesArray] = useState([]);
    const { user, accessToken } = useSelector((state) => state.auth);
    const [message, setMessage] = useState('');
    const socketRef = useRef(null);
    const messagesEndRef = useRef(null);

    const getMessages = async () => {
        const messages = await SuppotAPIs.getMessages(conversation_id);
        if (messages) {
            setMessagesArray(messages?.data?.conversations);
        }
    };


    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, []); 

    useLayoutEffect(() => {
        scrollToBottom(); 
    }, [messagesArray]);


    useEffect(() => {
        getMessages();
        const socket = new WebSocket(
            `${process.env.REACT_APP_SOCKET_URL}/api/v1/socket/ws/support?Authorization=__token_start__${encodeURIComponent(
                accessToken
            )}__token_end__%26__conversation_id_start__${encodeURIComponent(conversation_id)}__conversation_id_end__&`
        );

        socketRef.current = socket;

        socket.onopen = () => console.log('Connected to WebSocket server');

        socket.onmessage = (event) => {
            const responseMessage = event.data;
            const responseDate = new Date().toISOString();
            setMessagesArray((prevMessages) => [
                ...prevMessages,
                { conversation_id, message: responseMessage, sender_id: null, date: responseDate },
            ]);
        };

        socket.onclose = () => console.log('WebSocket connection closed');
        socket.onerror = (error) => console.error('WebSocket error:', error);

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [conversation_id, accessToken]);

    const handleSend = (userText) => {
        if (userText.trim() !== '') {
            socketRef.current.send(userText);
            const date = new Date().toISOString();
            setMessagesArray((prevMessages) => [
                ...prevMessages,
                { conversation_id, message: userText, sender_id: user?._id, date },
            ]);
            setMessage('');
        }
    };

    const handleMessageInput = (e) => setMessage(e.target.value);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSend(message);
        }
    };

    const checkMsgFromCurrentUser = (sender_id) => user?._id === sender_id;


    return (
        <div className="profile">
            <ProfileNav />
            <div className="support message">
                <div className="ask-leo-container">
                    <div className=" supportMessages conversation-box">
                        {messagesArray.length > 0 ? (
                            messagesArray.map((msg, index) => (
                                <div
                                    key={index}
                                    className={`prompt ${checkMsgFromCurrentUser(msg?.sender_id) ? 'user-message' : 'company-message'}`}
                                >
                                    <h1>{msg.message}</h1>
                                </div>
                            ))
                        ) : (
                            <div className="centered-text">
                                <h1>Support</h1>
                            </div>
                        )}
                        {/* Ref for auto-scrolling */}
                        <div ref={messagesEndRef} />
                    </div>
                    <div className="message-input-box">
                        <input
                            type="text"
                            placeholder="Message..."
                            value={message}
                            onChange={handleMessageInput}
                            onKeyDown={handleKeyPress}
                        />
                        <a>
                            <button onClick={() => handleSend(message)}>
                                <i className="fa-solid fa-paper-plane"></i>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
